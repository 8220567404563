@import '../../../css/functions';

.billing-summary {
    padding: 24px;
    border: solid 1px color('light-gray');
    border-radius: 6px;

    @media (min-width: 900px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
        padding: 32px;
    }

    .title {
        margin: 0;
    }

    &-item {
        padding: 12px 16px;
        border-radius: 8px;

        &-monthly-cost {
            background-color: color('light-bluegray');
        }
    }
}
