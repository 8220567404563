.agency-billing {
    .lc-stripe-card-input {
        margin-bottom: 24px;
    }

    &-actions {
        display: flex;
        justify-content: flex-end;

        .lc-button {
            margin-left: 16px;
        }
    }

    &-add-payment-method-empty {
        margin-bottom: 192px;
    }

    &-customer-data {
        display: flex;
        justify-content: space-between;
    }

    &-default-card {
        display: flex;
        justify-content: space-between;
    }
}
