.billing-history {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    &-charge {
        padding: 8px 0;
        border-top: 1px solid #e2e2e2;
        display: flex;
        justify-content: space-between;

        &:first-child {
            border-top: none;
        }
    }

    &-amount {
        font-weight: bold;
    }
}
