@import '../../../../css/variables';
@import '../../../../css/functions';

.app-section {
    padding: 24px;

    @media #{$mq-min-lg} {
        padding: 32px;
    }

    & + & {
        border-top: 4px solid color('lighter-gray');
    }

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 0 24px;

        .lc-form {
            display: flex;
        }

        .lc-input,
        .MuiFormControl-root.lc-input {
            margin-bottom: 0;
        }
    }

    &-title {
        margin: 0;
    }

    &-subtitle {
        margin: 4px 0 0 !important;
    }
}
