@import '../../../../../css/functions';

.view-switcher {
    z-index: initial;
    position: relative;
    margin-bottom: 8px;

    &-toggle {
        z-index: 2;
        position: relative;
        display: grid;
        align-items: center;
        column-gap: 16px;
        grid-template-columns: 36px minmax(0, 1fr) 24px;
        grid-template-areas: 'icon content caret';
        width: 100%;
        padding: 0;
        appearance: none;
        text-align: left;
        padding: 16px;
        border: solid 1px color('light-gray');
        border-radius: 6px;
        background-color: color('white');

        &-icon {
            grid-area: icon;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            color: color('bluegray');
            border: solid 1px color('light-gray');
            border-radius: 6px;
            font-weight: 700;
            font-size: 16px;
        }

        &-caret.lc-icon {
            grid-area: caret;
            color: color('bluegray');
        }

        &-content {
            position: relative;
            grid-area: content;
            line-height: 1.25;
            margin-right: -8px;
        }

        &-label,
        &-value {
            font-weight: 700;
            transition: all 0.15s ease-in-out;
        }

        &-label {
            top: 0;
            position: relative;
            font-size: 16px;
            color: color('darker-gray');
            transform: translateY(0);
        }

        &-value {
            top: 0;
            position: absolute;
            color: color('black');
            font-size: 16px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transform: translateY(18px);
        }

        &:hover,
        &:focus {
            background-color: color('lightest-gray');
        }

        &:hover &-label,
        &:focus &-label {
            color: color('black');
        }

        &:hover &-avatar {
            color: color('darker-gray');
            background-color: color('lighter-gray');
        }
    }

    &-has-value &-toggle {
        &-label {
            font-size: 12px;
            font-weight: 400;
            transform: translateY(-12px);
            color: color('darker-gray') !important;
        }

        &-value {
            transform: translateY(6px);
        }
    }

    &-dropdown {
        position: relative;
        padding: 16px;
        border-top: none;
        border: solid 1px color('lighter-gray');
        border-top: none;
        border-radius: 0 0 6px 6px;
        background-color: color('white');

        &-container {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-top: 69px;
            border-radius: 0 0 6px 6px;
            background-color: color('white');
            transform: translateY(-14px);
            opacity: 0;
            visibility: hidden;
            transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            background-color: transparent;
            pointer-events: none;
        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: color('lighter-gray');
        }
    }

    &-search-input {
        .lc-input.MuiFormControl-root {
            margin: 0;
        }
    }

    &-menu {
        padding: 0 0 12px;
        margin: 12px -4px -16px;
        list-style: none;
        max-height: 194px;
        overflow-y: auto;

        &-empty,
        &-loading {
            margin: 16px 0;
        }

        &-item {
            &-button {
                display: grid;
                column-gap: 8px;
                grid-template-columns: 28px minmax(0, 1fr) 24px;
                grid-template-areas: 'icon label indicator';
                width: 100%;
                padding: 4px;
                color: color('darker-gray');
                font-weight: 700;
                text-align: left;
                background: transparent;
                border-radius: 6px;
                outline: none;
            }

            &-avatar {
                pointer-events: none;
            }

            &-label {
                grid-area: label;
                grid-row: auto;
                padding: 2px 0;
                pointer-events: none;
                align-self: center;
            }

            &-selected-indicator {
                grid-area: indicator;
                display: none;
                margin-top: 2px;
                pointer-events: none;

                &-check.lc-icon {
                    width: 20px;
                    height: 20px;
                    color: color('primary_color');
                }

                &-close.lc-icon {
                    display: none;
                    width: 20px;
                    height: 20px;
                    color: color('darker-gray');
                }
            }

            &-active + &-inactive {
                &::before {
                    display: block;
                    content: 'Inactive';
                    margin: 12px 4px;
                    padding-top: 12px;
                    font-size: 12px;
                    font-weight: 700;
                    color: color('dark-gray');
                    border-top: solid 1px color('lighter-gray');
                }
            }

            &-inactive &-button {
                color: color('gray');
            }

            &-selected & {
                &-button {
                    color: color('black');
                }

                &-selected-indicator {
                    display: block;
                }
            }

            &-button:hover,
            &-button:focus {
                color: color('black');
                background-color: color('lightest-gray');
            }

            &-inactive &-button:hover {
                color: color('darker-gray');
            }

            &-button:hover &-avatar,
            &-button:focus &-avatar {
                color: color('darker-gray');
                background-color: color('lighter-gray');
            }

            &-button:hover &,
            &-button:focus & {
                &-selected-indicator {
                    &-check.lc-icon {
                        display: none;
                    }

                    &-close.lc-icon {
                        display: inline-flex;
                    }
                }
            }
        }
    }

    &-create-new {
        padding-top: 12px;
        margin-top: 16px;
        border-top: solid 1px color('lighter-gray');

        .lc-button {
            width: 100%;
        }
    }

    &-is-open {
        z-index: 10;
        border-radius: 6px 6px 0 0;
        border-bottom-color: transparent;
    }

    &-is-disabled,
    &-is-readonly {
        pointer-events: none;
    }

    &-is-open & {
        &-toggle {
            border-bottom: none;
            border-radius: 6px 6px 0 0;
        }

        &-toggle,
        &-dropdown {
            border-color: color('gray');
        }

        &-dropdown-container {
            pointer-events: auto;
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.15);
        }
    }

    &-is-readonly & {
        &-toggle {
            background-color: color('lightest-gray');
            border: none;

            :hover {
                background-color: color('lightest-gray');
            }

            &-caret.lc-icon {
                display: none;
            }

            .avatar {
                color: color('darker-gray');
                background-color: color('lighter-gray');
            }
        }
    }
}
