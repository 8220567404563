@import '../../../../css/functions';

.household-size-input {
    min-width: 120px;

    @media (min-width: 600px) {
        min-width: 248px;
    }

    &,
    .MuiFormControl-root.lc-input {
        margin-bottom: 0;
    }
}

.households-table {
    width: 100%;
    border-collapse: collapse;

    &-wrapper {
        overflow-x: auto;
    }

    &-header {
        color: color('bluegray');
        font-weight: 400;
    }

    &-header,
    &-data {
        padding: 8px;
        min-width: 0;
    }
}
