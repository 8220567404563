@import '../../../css/functions';

.billing-companies {
    &-list {
        &-item,
        &-header {
            display: grid;
            grid-template-columns: 3fr 1fr;
            gap: 8px;

            &-company {
                display: flex;
                align-items: center;

                &-name {
                    display: inline-block;
                    margin-right: 8px;
                }
            }

            .lc-label {
                position: relative;
                top: -1px;
            }

            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                margin-right: 8px;
                border: solid 1px color('light-gray');
                border-radius: 6px;

                &-image {
                    width: 32px;
                    height: 32px;
                    background: center center no-repeat;
                    background-size: contain;
                    object-fit: contain;
                }
            }

            &-monthly-cost {
                text-align: right;
            }
        }

        &-view-all {
            display: flex;
            align-items: center;
            margin-top: 24px;

            &-count {
                color: color('bluegray');
                margin-left: 12px;
            }
        }
    }
}
