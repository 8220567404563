@import '../../css/functions';
@import '../../css/mixins';
@import '../../css/variables';

.clarity-dashboard {

    .lc-pie-chart-legend-label {
        .lc-pie-chart-legend-label-name {
            min-width: 130px;
            word-break: break-all;
        }

        .lc-pie-chart-legend-label-percentage {
            min-width: 50px;
            text-align: right;
        }

        .lc-pie-chart-legend-label-total {
            margin-left: 10px;
            min-width: 30px;
        }
    }
    .chart-card__total__value {
        color: #FF0000;
        font-size: 14px;
    }

    .clarity-logo {
        width: 120px;
        height: 44px;
    }

    &-daterange {
        color: color('darker-gray');
        font-size: 8px;
    }

    &-tabs {

        .MuiPaper-root,
        .MuiTab-root,
        .MuiTab-root .MuiTab-root {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }

    &-chart-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 8px;

        @media #{$mq-min-xs} {
            gap: 16px;
        }

        @media #{$mq-min-lg} {
            gap: 20px;
        }

        @media #{$mq-min-xl} {
            gap: 24px;
        }

        &-item {
            &.Wrapper {
                @media #{$mq-min-lg} {
                    flex: 1 1 calc(50% - 16px);
                }
            }

            &-full-width {
                min-width: 100%;
            }

            .chart-card {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.clarity-dashboard-modal {
    .MuiCardContent-root {
        overflow-y: scroll;
    }
}

.chart-card .lc-chart-pie {
    grid-template-columns: minmax(0, 380px) 290px !important;
}

// TODO: Consider moving some of these print styles to a global `print.scss` file - or move them to their respective component files.
@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }

    .layout-wrapper {
        display: block !important;
    }

    .layout-header {
        display: none;
    }

    .layout-sidebar {
        display: none;
    }

    .clarity-dashboard {

        .app-header .action-list,
        .MuiAppBar-root,
        .app-section-header {
            display: none;
        }

        .app-header {
            border-bottom: none;
            border-radius: var(--border-radius);
            margin: 8px 8px 0;
            padding: 16px 24px 16px 16px;
        }

        [id*='tabpanel-'] {
            padding-top: 8px;
        }

        &-stat-grid {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            padding: 8px 16px;
            margin-left: -8px;
            width: calc(100% + 32px);

            .stat-card {
                width: calc(33.3% - 16px);
            }
        }

        .app-card {
            box-shadow: none;
        }

        .stat-card {
            column-span: 4;
        }

        .chart-card {
            display: flex;
            flex-direction: column;

            &__title {
                font-size: 16px;
            }

            &__empty {
                &__illustration {
                    height: 160px;
                }

                &__description {
                    font-size: 12px;
                }
            }
        }

        .clarity-chart-card {
            min-height: 317px;
        }

        .dnd-action,
        .app-card__help {
            display: none;
        }

        &-chart-grid {
            display: table !important;

            &-item {
                &.Wrapper {
                    width: 100%;
                    max-width: 100%;
                    display: inline-block;
                    break-inside: avoid;
                    padding: 0;
                }

                .Item {
                    margin: 8px;
                }
            }
        }

        .lc-chart {
            margin: 0 auto;

            & > * {
                display: inline-block;
                vertical-align: middle;
            }

            &-legend {
                margin-bottom: 0;
                padding-bottom: 0;

                &-item-button {
                    border: none !important;
                    box-shadow: none !important;
                    padding: 0;

                    .lc-chart-label {
                        color: color('black') !important;
                        font-size: 12px !important;
                    }
                }
            }
        }

        .lc-chart-line,
        .lc-chart-bar {
            height: 100%;
            width: 100%;

            .lc-chart-canvas-wrapper,
            .lc-chart-legend-wrapper {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto !important;

                // Source: https://stackoverflow.com/questions/41674976/resize-chart-js-canvas-for-printing/47928475
                canvas {
                    min-height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    height: auto !important;
                    width: auto !important;
                }

                .lc-chart-legend {
                    justify-content: center;
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .lc-chart-pie {
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 1 1 auto;
            gap: 0;
            margin-top: -40px;

            .lc-chart-canvas-wrapper,
            .lc-chart-legend-wrapper {
                flex: 0 0 50%;
                min-width: 50%;
                max-width: 50%;
            }

            .lc-chart-canvas-wrapper {
                height: 50%;

                canvas {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            .lc-pie-chart-legend {
                display: block;
                padding-left: 20px;
                max-width: 100%;
                justify-content: flex-end !important;

                &-item {
                    max-width: 100%;
                }

                &-label {
                    font-size: 12px !important;
                }

                &-color {
                    flex: 0 0 11px;
                }
            }
        }
    }
}

.clarity-stat-card {
    border-radius: 8px;
    background-color: rgb(245, 246, 250);
    display: inline-flex;
    width: max-content;
    margin: 10px;
    padding: 8px;

    .stat-card-value {
        width: 180px;
    }

    .lc-list-group__item__label {
        font-size: medium;
    }

    .lc-list-group__item__value {
        .stat-card-value {
            font-size: xx-large;
            text-align: center;
            display: block;
        }
    }
}

.stat-card-previous-value-up {
    color: green;
}
.stat-card-previous-value-down {
    color: red;
}
