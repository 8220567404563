@import '../../css/variables';
@import '../../css/functions';

.clarity-report-details {
    margin: 12px 0px;
    padding: 12px 24px 24px 24px;
    background-color: color('lightest-gray');
    border-radius: 8px;
}

.clarity-filter-explain-list {
    margin: 12px;

    > span {
        padding: 4px 8px;
        margin: 8px;
        background-color: color('lighter-gray');
        border-radius: 6px;
    }
}

.clarity-report-request {
    &-status {
        &-requested {
            pointer-events: none;

            .app-list-item-content,
            .app-list-item-details {
                opacity: 0.5;
            }
        }

        &-requested,
        &-expired {
            background: color('lightest-gray');
        }

        &-failed {
            border-color: color('red') !important;
        }

        &-failed .lc-icon-decorative {
            background-color: color('red') !important;
        }

        &-failed &-label {
            color: color('red');
        }

        &-expired &-label {
            color: color('dark-gray');
        }
    }

    &-expires-at {
        color: color('red');
    }
}

// TODO: It might be good to make this sort of snackbar reusable.
.clarity-report-snackbar {
    &.snackbar {
        .lc-icon,
        .MuiCircularProgress-root {
            margin-left: 8px;
            margin-right: 16px;
            flex-shrink: 0;
        }

        .lc-icon-check {
            color: color('green');
        }

        .lc-icon-error {
            color: color('red');
        }

        .MuiSnackbarContent-root {
            color: color('black') !important;
            background-color: color('white') !important;
            border: solid 1px color('lighter-gray');

            .snackbar-action {
                color: color('white');
                background-color: color('primary_color');

                &:hover {
                    color: color('white') !important;
                    background-color: color('primary_color_dark') !important;
                }
            }
        }

        .MuiSnackbarContent-action {
            margin-right: 0;
            padding: 8px 0 8px 16px;
        }
    }
}

@media #{$mq-min-lg} {
    .clarity-report-list-item {
        .app-list-item-content {
            min-width: 450px;
            max-width: 450px;
        }

        .app-list-item-details {
            min-width: 250px;
            max-width: 250px;
        }
    }

    .clarity-report-list-actions {
        min-width: 125px;
        max-width: 125px;
    }
}

.clarity-search-reports{
    width: 200px;
    margin-left: auto;
}
