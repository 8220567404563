@import './bootstrap';
@import './bootstrap-table';
@import './functions';
@import './colors';
@import './css-variables';
@import './theme';
@import './variables';
@import './typography';
@import './animate';
@import './widget';
@import './helpers';
@import './alerts';
@import './notification-alert';
@import './modal-company';
@import './crm-rule-manager';
@import './utilities';
@import './components';
@import '~@lambdacurry/component-library/dist/styles/index.css';

/* == TODO: Remove these styles once we have reskinned all pages. == */

@import './button-overrides';
@import './temp-style-fixes';

/* ================================================================== */

*,
::before,
::after {
    border-width: 0;
    border-style: solid;
}

b,
strong {
    font-weight: 400;
}

.bold {
    font-weight: bold;
}

p {
    font-size: 14px;
    line-height: 1.7em;
}

.form-group,
.form-group input,
label,
input,
input::placeholder,
textarea,
.form-group textarea,
.form-control {
    color: #555;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;

    &.loading {
        .bg,
        #sidebar,
        #main-container,
        #content {
            display: none;
        }
    }
}

.holder__table thead tr th {
    min-width: inherit;
    max-width: inherit;
    width: inherit;
}

.holder__table tbody tr td {
    min-width: inherit;
    max-width: inherit;
    width: inherit;
}

.holder__table .table thead tr th:nth-child(2) {
    width: 5%;
    min-width: 80px;
}

.holder__table .table tbody tr td:nth-child(2) {
    width: 5%;
    min-width: 80px;
}

.holder__table .table thead tr th:nth-child(4) {
    width: 6%;
    min-width: 100px;
}

.holder__table .table tbody tr td:nth-child(4) {
    width: 6%;
    min-width: 100px;
}

.holder__table .table thead tr th:last-child {
    width: 12%;
    min-width: 200px;
}

.holder__table .table tbody tr td:last-child {
    width: 12%;
    min-width: 200px;
}

.customBootStrapTable {
    font-size: 17px;
}

.cm-offers-table-wrapper .react-bs-table td {
    white-space: normal;
}

.features-table-wrapper .react-bs-table td {
    vertical-align: middle;
    border-top: 1px solid #ddd;
    white-space: normal;
    height: auto;
}

.features-table-wrapper .react-bs-table th {
    white-space: normal;
}

.features-table-wrapper .react-bs-table td {
    vertical-align: middle;
    border-top: 1px solid #ddd;
    white-space: normal;
    height: auto;
}

.featureFlags-table-wrapper .react-bs-table td {
    vertical-align: middle;
    border-top: 1px solid #ddd;
    height: auto;
}

.react-bootstrap-table-header {
    font-size: 20px !important;
}

/* Customize the label (the container) */

.BootstrapTableHeader {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0px;
}

.btn__save-exceptions .btn-default {
    width: 100%;
}

/* Hide the browser's default checkbox */

.BootstrapTableDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Create a custom checkbox */

.BootstrapTableHeaderCheckbox {
    height: 15px;
    width: 15px;
    background-color: #eee;
    margin: 0px !important;
}

/* On mouse-over, add a grey background color */

.BootstrapTableHeader:hover input ~ .BootstrapTableHeaderCheckbox {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.BootstrapTableHeader input:checked ~ .BootstrapTableHeaderCheckbox {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.BootstrapTableHeader input:checked ~ .BootstrapTableHeaderCheckbox:after {
    display: block;
}

/* Style the checkmark/indicator */

.BootstrapTableHeader .BootstrapTableHeaderCheckbox:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.margin-none {
    margin: 0;
}

.margin-left {
    margin-left: 5px;
}

.relative {
    position: relative;
}

.align-center {
    text-align: center;
}

.text-wrapped {
    white-space: pre-line;
}

.text-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.hold-csv-table .csv-table {
    display: block;
}

.matchback-holder-table thead tr {
    display: flex;
}

.matchback-holder-table thead tr .head-th {
    display: flex;
    flex-direction: column;
}

.matchback-holder-table thead tr .head-th .text-title {
    flex-grow: 1;
}

.matchback-holder-table tbody {
    display: block;
}

.logo {
    width: 95%;
    max-width: 350px;
    margin: auto;
    display: block;
}

.container {
    margin-bottom: 50px;
    margin-top: 15px;
    width: 100%;
}

.matchback-holder-table thead {
    display: block;
}

.main-container {
    margin-bottom: 0;
    max-width: 1542px;
}

@media screen and (max-width: 786px) {
    .main-container {
        overflow: inherit;
    }
}

.daytime-dayoff {
    position: relative;
}

.wrap-position-primery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.wrapper-holder__tible-leads thead tr th,
td {
    cursor: default !important;
}

.holder__tible-user thead tr th:first-child {
    min-width: 50px;
    max-width: 50px;
}

.holder__tible-user tbody tr td:first-child {
    min-width: 50px;
    max-width: 50px;
}

.holder__tible-user thead tr th:last-child {
    min-width: 250px;
    max-width: 250px;
}

.holder__tible-user tbody tr td:last-child {
    min-width: 250px;
    max-width: 250px;
}

.holder__tible-user table thead tr th {
    min-width: 180px;
    max-width: 180px;
}

.holder__tible-user table tbody tr td {
    min-width: 180px;
    max-width: 180px;
}

.wrapper-holder__tible-leads thead tr th:nth-child(1) {
    min-width: 50px;
    max-width: 50px;
}

.wrapper-holder__tible-leads tbody tr td:nth-child(1) {
    min-width: 50px;
    max-width: 50px;
}

.wrapper-holder__tible-leads thead tr th:nth-child(2) {
    min-width: 180px;
    max-width: 180px;
}

.wrapper-holder__tible-leads tbody tr td:nth-child(2) {
    min-width: 180px;
    max-width: 180px;
}

.wrapper-holder__tible-leads thead tr th:nth-child(3) {
    min-width: 250px;
    max-width: 250px;
}

.wrapper-holder__tible-leads tbody tr td:nth-child(3) {
    min-width: 250px;
    max-width: 250px;
}

.wrapper-holder__tible-leads thead tr th:nth-child(4) {
    min-width: 200px;
    max-width: 200px;
}

.wrapper-holder__tible-leads tbody tr td:nth-child(4) {
    min-width: 200px;
    max-width: 200px;
}

.wrapper-holder__tible-leads thead tr th:nth-child(5) {
    min-width: 470px;
}

.wrapper-holder__tible-leads tbody tr td:nth-child(5) {
    min-width: 470px;
}

.filter-active {
    color: #555;
    border: 1px solid color('secondary_color');
    border-radius: 4px;
    font-weight: 400;
}

.filter-active .daterangepicker {
    font-weight: 300;
}

.filter-active .input-group > input:first-child {
    font-weight: 400;
    position: relative;
    overflow: hidden;
}

.filter-active .rbt-aux .rbt-close {
    font-size: 30px;
    font-weight: 400;
    margin-top: 0;
}

.filter-active .input-group .form-control {
    margin: 0 0 -0.5px;
}

.filter-active .input-group .input-group-addon {
    height: 32px;
}

.page-header {
    margin: 10px 0 20px;
}

.header-line__holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.header-line__holder .page-header {
    border-bottom: none;
    margin: 0;
}

.hold__icon-text span {
    font-size: 2em;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.hold__icon-text span i {
    margin-right: 5px;
    width: 55px;
    height: 55px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-number {
    display: inline-block;
    width: 30%;
    min-width: 50px;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-number-text {
    display: inline-block;
    width: 70%;
    font-size: 18px;
    font-weight: 100;
    text-align: center;
}

.csv-table {
    border: 1px solid #ddd;
    border-radius: 5px;
    display: block;
    overflow-x: auto;
    position: relative;
}

.csv-table tbody {
    background: #f9f9f9;
}

.csv-table thead tr th {
    font-size: 16px;
    font-weight: 400;
    min-width: 200px;
    max-width: 200px;
    overflow-wrap: break-word;
    width: 20%;
    vertical-align: top;
    border: none;
}

.csv-table thead tr th {
    min-width: 200px;
    overflow-wrap: break-word;
}

.csv-table tbody tr td {
    min-width: 200px;
    overflow-wrap: break-word;
    text-align: left;
}

.csv-table tbody tr td {
    border-top: 0;
    overflow-wrap: break-word;
}

.csv-table .csv-row td {
    min-width: 200px;
    width: 20%;
}

.csv-table .csv-row {
    display: flex;
}

.csv-table thead {
    display: flex;
}

.csv-table .csv-row td {
    min-width: 200px;
    max-width: 200px;
    width: 100%;
}

.csv-table thead tr th:first-child {
    min-width: 50px;
    width: 5%;
}

.csv-table tbody tr td:first-child {
    min-width: 50px;
    width: 5%;
}

.csv-table .csv-row-error td {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.csv-table .csv-row-sucess td {
    background: #f9f9f9;
}

.csv-table .csv-row-error .csv-col-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    position: relative;
    padding: 8px 8px 8px 30px;
}

.csv-row-error .csv-col-error .csv-glyphicon {
    position: absolute;
    top: 8px;
    left: 8px;
}

.csv-row-sucess .csv-col-error .csv-glyphicon {
    display: none;
}

.holder-scoreboard__box {
    display: flex;
    flex-wrap: wrap;
}

.holder-scoreboard__box .scoreboard-widget {
    width: 20%;
    min-height: 300px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1440px) {
    .holder-scoreboard__box .scoreboard-widget {
        width: 20%;
    }

    .holder-scoreboard__box .scoreboard-widget.col-lg-6 {
        width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .holder-scoreboard__box .scoreboard-widget {
        width: 50%;
    }
}

@media screen and (max-width: 880px) {
    .holder-scoreboard__box .scoreboard-widget {
        width: 100%;
    }
}

.holder-scoreboard__box .scoreboard-widget .jumbotron {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.holder-scoreboard__box .scoreboard-widget .jumbotron h1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 20pt;
    font-weight: inherit;
}

.csv-none-button {
    margin: 0 0 10px;
}

.col-narrow {
    padding-left: 0;
    padding-right: 4px;

    p {
        margin: 0;
    }
}

//  SMS messages inbox

.date-number {
    min-width: 155px;
}

.inbox-list-group {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }

    .col-xs-6 {
        padding-left: 4px;
        padding-right: 4px;

        @media (max-width: 500px) {
            width: 100%;
        }
        min-width: 150px;
        p {
            display: inline-block;
        }
    }
}

.group-second {
    display: flex;
    justify-content: left;

    .first-col-number {
        padding-left: 15px;
        padding-right: 15px;
        min-width: 150px;
        width: 100%;
        color: #595959;

        @media (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.first-col {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1400px) {
        flex-wrap: wrap;
    }
    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.first-col-number {
    display: flex;
    justify-content: space-between;
    min-width: 240px;
    padding-right: 4px;
    height: 23px;

    @media (max-width: 1400px) {
        min-width: 210px;
    }
    p {
        margin: 0;
    }
}

.last-col-number {
    display: flex;
    min-width: 185px;
}

.preview-text {
    width: 100%;
    color: #6b6b6b;
    padding: 0 25px;
}

.cursor-disabled {
    cursor: not-allowed;
}

.load-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
    transition: 0.3s ease;
}

.selected {
    color: #3c763d;
    font-weight: 500;
}

.holder-col-box .save-changes {
    padding: 0;
}

.now-indent {
    padding: 0;
}

.holder-import-box {
    float: inherit;
}

.holder-import-matchback {
    margin: 0 0 30px;
    float: inherit;
}

.holder-preview .btn-default {
    margin-bottom: 10px;
}

.show-selected-link {
    margin: 0 0 40px;
}

.wrapper__link .form-group {
    margin-bottom: 0;
}

.wrapper__link .form-group .help-block {
    margin-bottom: 0;
}

.show-selected-link .form-group {
    margin-bottom: 0;
}

.show-selected-link .form-group .help-block {
    margin-bottom: 0;
}

.holiday-btn div .btn-primary {
    margin: 0 0 10px;
}

.holiday-btn .row {
    display: flex;
}

.wrapper-metric-sessions {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 991px) {
    .wrapper-metric-sessions {
        margin-left: -15px;
        margin-right: -15px;
    }
}

.float-none {
    float: none;
}

.wrapper-metric-sessions .daterangepickercontainer {
    width: 100% !important;
    margin-bottom: 0 !important;
    position: static;
}

// Date Range Picker Container

.daterangepickercontainer {
    .daterangepicker {
        width: calc(100vw - 280px);
        right: 15px;
        left: auto !important;
        max-width: inherit;
        justify-content: space-between;

        @media (max-width: 991px) {
            right: 0;
            left: 0 !important;
            width: inherit;
            flex-wrap: wrap;
        }

        @media (max-width: 786px) {
            width: calc(100vw - 30px);
        }
    }
}

.holder-rangecontainer {
    position: relative;

    .rangecontainer {
        width: 30%;
        float: left;
        display: block !important;
        @media (max-width: 960px) {
            width: 100%;
        }
    }
    .fromDateTimeContainer {
        width: 33.33%;
        float: left;
        @media (max-width: 1250px) {
            width: 32%;
        }
        @media (max-width: 960px) {
            width: 48%;
        }
        @media (max-width: 570px) {
            width: 100%;
        }
    }
}

.wrapper-metric-sessions .input-group {
    margin-top: 0;
    margin-bottom: 0;
}

.wrapper-metric__bottom {
    margin-left: -15px;
    margin-right: -15px;
}

.wrapper-metric__bottom .btn {
    margin-left: 15px;
}

.wrapper-metric__bottom .btn-primary {
    margin-top: 0;
    margin-bottom: 20px;
}

.metric-name-box {
    margin: 0 0 15px;
    position: inherit;
}

.metric-name-box .leadCount {
    position: relative;
    display: inline-grid;
    color: color('secondary_color');
    background-color: #efefef;
    padding: 20px;
    width: 150px;
    border: 1px solid #000000;
    line-height: 16px;
}

.wrapper-metric__bottom .metric-name-box.first-box-metric {
    max-width: 180px;
}

@media (max-width: 991px) {
    .wrapper-metric__bottom {
        margin-left: 0;
        margin-right: 0;
    }

    .wrapper-metric__bottom .btn {
        margin-left: 0;
    }

    .wrapper-metric__bottom .metric-name-box.first-box-metric {
        padding: 0;
        max-width: initial;
    }
}

.metric-name-box .leadCount div {
    height: 32px;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}

.metric-name-box .leadCount div.moduleName {
    font-size: 18px;
    color: #000;
    height: 32px;
    font-weight: 500;
}

.text-muted {
    margin: 0 0 15px;
}

@media screen and (max-width: 768px) {
    .metric-name-box {
        width: 100%;
    }
}

.bg {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.5) 100%)
        color('secondary_color');
}

/* // Insite Defined Fields table // */

.insite-defined-holder table tr th:nth-child(3) {
    width: 300px;
}

.insite-defined-holder table tr td:nth-child(3) {
    width: 300px;
}

.insite-defined-holder [dataField='data'] {
    white-space: inherit;
}

.insite-defined-holder .react-bs-table table td {
    white-space: inherit;
}

@media screen and (max-width: 1220px) {
    .insite-defined-holder .react-bs-table table {
        table-layout: auto;
    }
}

.special-holder .help-block {
    margin-top: 0;
    margin-bottom: 0;
    color: #a94442;
    display: flex;
    flex-direction: column;
}

.special-holder .btn {
    min-width: 120px;
    margin-top: 0;
    margin-bottom: 0;
}

.insite-defined-holder table tr td ul {
    padding: 0;
    margin: 0;
}

.btn__holder-block .btn {
    min-width: 111px;
}

/* // Lead Details Page Reminders/Email list // */

.hold-box-list {
    max-height: 355px;
    min-height: 58px;
    overflow-x: hidden;
    padding: 0 20px;
    margin: 20px 0;
}

.hold-box-list .list-group__holder {
    height: initial;
    overflow-y: inherit;
}

.group-list_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hold-box-list .list-group:last-child {
    margin-bottom: 0;
}

.hold-box-list .list-group li {
    font-size: 14px;
    padding: 15px 4px;
    font-weight: 400;
    color: #595959;
    border: 0;
    border-bottom: 1px solid #ddd;
    position: relative;
    margin-bottom: 1px;
    transition: background 0.3s linear;
    border-radius: 0 !important;
}

.list-group__holder li:hover {
    background: rgba(221, 221, 221, 0.6);
}

.list-group__holder li .date-text {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 200;
    color: #a6a6a6;
    margin-top: 5px;
}

.list-group__holder li .email-list__box {
    display: flex;
    justify-content: space-between;
}

.list-group__holder li .email-list__box .date-text {
    display: block;
    margin-top: 0;
}

.position-block {
    width: 100%;
}

.position-block .btn {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 35px;
    z-index: 2;
    padding: 0;
    display: flex;
}

.position-block .btn .clx-icon {
    padding: 0 5px;
}

.position-block .btn .buttonText {
    padding-left: 5px;
}

@media (max-width: 768px) {
    .position-block .btn {
        padding: 0 5px;
    }
}

.position-block .panel-primary {
    margin-bottom: 0;
}

.position-block .panel {
    border: none;
}

.position-block .collapse {
    border: 1px solid #b6b6b6;
}

.pannel-holder__collapse .collapse.in {
    padding: 15px;
}

.pannel-holder__collapse .collapse.in .panel-body {
    overflow-x: auto;
    position: relative;
    padding: 0;
}

.position-block .panel-heading {
    border-color: inherit;
    margin: 0 0 -1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 75px;

    button {
        margin-right: 40px;
        border: 1px solid #fff;
    }
}

.position-block .panel-heading::before {
    top: 50% !important;
    transform: translateY(-50%) rotate(90deg) !important;
}

.position-block .panel-body {
    padding: 0;
}

.position-block .panel-heading .panel-title h4 {
    margin: 0;
    text-transform: uppercase;
    cursor: pointer;
}

.position-block .panel-heading .block-head__notification .panel-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1px 15px;
    display: flex;
    align-items: center;
}

.position-block .col-block-2 {
    cursor: pointer;
    padding: 8px 15px;
}

.position-block .col-block-2 .block-notification {
    color: #888888;
}

.position-block .col-block-2 .block-notification:hover {
    text-decoration: underline;
}

.col-block {
    width: 100%;
}

@media (max-width: 900px) {
    .col-block {
        width: 600px;
    }
}

.checkbox label {
    display: flex;
    align-items: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100% !important;
}

.new-city-holder {
    margin-right: 0;
    margin-left: 0;
}

/* //  Datepicker */

.form__holder-datapicker .react-datepicker-popper {
    right: 0;
}

.form__holder-datapicker .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 40px !important;
    font-size: 12px;
    font-weight: 400;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 40px;
}

.form__holder-datapicker .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__time-list {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 22px;
}

.form__holder-datapicker .react-datepicker-time__header {
    padding-top: 6px;
    padding-bottom: 6px;
}

.form__holder-datapicker .react-datepicker__month-container {
    max-height: 210px;
    overflow: hidden;
}

.form__holder-datapicker .react-datepicker__month-container .react-datepicker__day {
    line-height: 2.7rem;
    font-size: 15px;
}

.form__holder-datapicker li.react-datepicker__time-list-item {
    font-size: 15px;
}

.react-datepicker__time-container {
    width: 71px !important;
}

@media (max-width: 1200px) {
    .form__holder-datapicker .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 30px !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 30px;
    }

    .form__holder-datapicker .react-datepicker-time__header {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

@media (max-width: 1060px) {
    .form__holder-datapicker .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 25px !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 25px;
    }
}

@media (max-width: 992px) {
    .form__holder-datapicker .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 27px !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 27px;
    }
}

.title-list-block {
    color: #a6a6a6;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin: 0 0 10px;
}

.title-list-block h5 {
    max-width: 175px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: #fff;
    z-index: 2;
}

.title-list-block::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: -62%;
    right: auto;
    top: 50%;
    transform: translateY(-50%);
    background: #a6a6a6;
    z-index: 0;
}

.title-list-block::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: auto;
    right: -62%;
    top: 50%;
    transform: translateY(-50%);
    background: #a6a6a6;
    z-index: 0;
}

.text-decor {
    color: #a6a6a6;
    text-decoration: line-through;
}

.red-buttom-overdue {
    border: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 10px;
    border-radius: 20px;
    font-weight: 200;
    width: 100%;
    min-width: 80px !important;
    max-width: 66px;
    margin: 0 10px 0 0;
    padding: 3px;
    z-index: 3;
}

.widget-settings .input-group {
    margin-top: 0;
    margin-bottom: 0;
}

#login-disclaimer {
    color: #fff;
    font-size: 12px;
}

#login-disclaimer a {
    font-weight: 300 !important;
}

#sign-in {
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.04em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
}

#sign-in a {
    font-weight: 800;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 0.04em;
}

.close {
    font-size: 30px;
    font-weight: 300;
}

#sign-in label {
    display: block;
    text-align: left;
}

#sign-in input.login-input-field {
    margin-top: 0;
}

#sign-in input::placeholder,
#sign-in input::-moz-placeholder {
    color: #999999;
}

#sign-in input {
    padding: 0 20px;
    height: 42px;
    margin-top: 20px;
    text-align: center;
}

.login-title {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.login-alert {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0;
    display: block;
    min-height: 50px;
}

#sign-in form {
    width: 300px;
    padding: 20px;
}

#form-logo {
    font-size: 24px;
    font-weight: bold;

    img {
        width: 100%;
        max-width: 300px;
        margin: auto;
        display: block;
    }
}

#sign-in .login-error-message {
    color: color('primary_button_text_color');
    text-align: left;
    padding: 4px;
    margin-bottom: 8px;
    text-align: center;
    display: block;
    border-radius: 4px;
    border: 2px solid red;
}

label {
    // Note: This is an unnecessarily unspecific rule that is problematic for our re-skin.
    // Changing to 500 for now to match our new label styles. Need to determine the impact of this site-wide.
    font-weight: 500;
}

.min-height {
    min-height: 275px;
}

.well .save-changes {
    margin-bottom: 0;
}

.btns-row {
    min-width: 210px;
}

.checkbox label,
.radio label {
    font-weight: 300;
    padding-left: 20px;
}

.holder-check-block {
    margin: 0 0 15px -5px;
}

.holder-panel {
    margin-top: 0;
}

.panel {
    display: flex;
    flex-direction: column;
}

.second-holder-flex {
    display: flex;
    flex-direction: column;
}

.checkbox label input[type='checkbox'] {
    margin: 0 0 0 -25px;
}

.radio label input[type='checkbox'] {
    margin: 0 0 0 -25px;
}

.panel .panel-heading {
    position: relative;
}

.panel-primary > .panel-heading {
    background-color: color('secondary_color');
    border-color: color('secondary_color');
    color: color('secondary_button_text_color');
}

.panel .panel-heading:before {
    content: '\f10f';
    font-size: 34px;
    transform: rotate(90deg);
    display: inline-block;
    font-family: 'clxicon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    position: absolute;
    right: 15px;
    top: 15px;
    pointer-events: none;
}

/*============= TCCS-279.questions =============*/

.answer-holder .type-line {
    margin: 10px 0;
    padding: 0 0 0 20px;
}

.answer-holder .type-line .btn {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 111px;
}

/*============= Buttons ================*/

.btn {
    margin-top: 10px;
    margin-bottom: 10px;
}

// TODO: Figure out how to override these without extending.
// .btn-default {
//     @extend .button;
//     @extend .button-default;
// }

// .btn-primary {
//     @extend .button;
//     @extend .button-primary;
// }

// .btn-green {
//     @extend .button;
//     @extend .button-accent;
// }

.btn-sidebar {
    background: #a1a1a1;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -1px;
    margin-top: -1px;
    display: none;
}

.btn.react-bs-table-csv-btn {
    margin-top: 0;
}

button {
    transition: background 0.25s, color 0.25s;
    -webkit-transition: background 0.25s, color 0.25s;
}

.btn-green i {
    font-size: 24px;
}

.well .save-changes .btn {
    margin-bottom: 0;
    margin-top: 0;
}

#pageDropDown {
    margin-top: 0px;
}

.btn-toolbar .btn {
    min-width: 111px;
}

.fixed-controls .control-button-group {
    position: fixed;
    right: 10px;
    background-color: #fff;
    z-index: 5;
    bottom: 0;
    padding: 5px 5px 10px 5px;
    border-radius: 4px;
}

.fixed-controls .control-button-group button {
    margin-top: 0;
    margin-bottom: 0;
}

.fixed-controls .control-button-group button:first-child {
    margin-left: 0;
}

.react-bs-table .table-bordered > thead > tr > th {
    font-size: 16px;
    font-weight: 400;
}

/* hidden menu on desktop/laptop/tablet devices */

@media (min-width: 769px) {
    #content > .navbar {
        display: none;
    }
}

.react-bs-table {
    overflow: hidden;
    overflow-x: auto;
}

.react-bs-table table {
    margin-bottom: 0;
}

.react-bs-table table tbody tr {
    cursor: pointer;
}

.react-bs-table table td div,
.react-bs-table table th div {
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-bs-table .react-bs-container-header th:not(.sort-column) {
    cursor: default;
}

.sort-column {
    white-space: nowrap !important;
}

.react-bs-container-header {
    overflow: initial;
    width: 100%;
}

.react-bs-container-body {
    overflow: initial;
    width: 100%;
}

.react-bs-container-footer {
    overflow: initial;
    width: 100%;
}

/*============== DayTime Table ===============*/

.daytime-form {
    margin: 15px 0;
}

.daytime-form-header .daytime-title {
    font-weight: 400;
    min-width: 121px;
    margin-right: 5px;
}

.daytime-form-header .daytime-title:last-child {
    margin-right: 0;
}

.daytime-sheduler,
.daytime-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    @media (max-width: 768px) {
        justify-content: flex-start;
    }
}

.daytime-sheduler .daytime-control {
    width: 210px;
    font-weight: 400;
}

.first-line {
    width: 210px;
}

.second-line {
    width: 45px;
}

.block-indent__hold .panel-body {
    padding-right: 0;
    padding-left: 0;
}

.block-indent__hold .col-xs-12 {
    padding-right: 3px;
    padding-left: 8px;
}

.margin-none-box {
    margin-left: 0;
    margin-right: 0;
}

.indent-chat {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
}

.indent-box {
    margin-left: 15px;
    margin-right: 15px;
}

.none-indent {
    padding-left: 0;
    padding-right: 0;
}

.btn-none-indent {
    margin: 0;
}

.indent-box .panel-body {
    padding-right: 0;
    padding-left: 0;
}

.prospect-padding .indent-outside {
    margin-left: 0;
    margin-right: 0;
}

.block-indent__padding {
    padding-right: 15px;
    padding-left: 15px;
}

.block-indent__padding .form-group {
    padding-right: 15px;
    padding-left: 15px;
}

.block-indent__padding .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
}

.block-indent__padding .btn {
    min-width: 111px;
}

.right-indent {
    margin-right: 5px;
}

.block-indent__widget-padding {
    padding-right: 13px;
    padding-left: 13px;
}

.block-indent__widget-padding #is_mini {
    margin: 0 0 0 -20px;
}

.block-indent__padding .type-line {
    padding: 0;
}

.indent-outside {
    margin-left: -15px;
    margin-right: -15px;
}

.indent-outside table tr th {
    font-weight: 300;
}

.new-holder-indent {
    overflow: hidden;
}

.holder-block-wrap {
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.new-box {
    overflow: hidden;
    padding: 20px 15px 0 15px;
}

.holder-block-wrap .form-group .control-label {
    position: absolute;
    top: 0;
    left: 15px;
}

.holder-block-wrap span {
    background: none;
    color: #000;
    padding: 5px 0 0;
    font-size: 14px;
    font-weight: 300;
}

.new-box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background: #f7efef;
}

.holder-block-wrap .col-md-2 {
    max-width: 110px;
    min-width: 80px;
    width: 100%;
    position: initial;
    margin-top: 25px;
    padding-right: 0;
    padding-left: 15px;
}

@media screen and (max-width: 1200px) {
    .second-line {
        width: 4%;
    }
}

.wrapp-copy {
    display: inline-block;
    margin: 0;
    text-align: center;
    min-height: inherit !important;
    min-width: 75px;
    padding: 6px 10px;
    line-height: 1.42857143;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    -o-transition: background 0.25s, color 0.25s;
    transition: background 0.25s, color 0.25s;
    -webkit-transition: background 0.25s, color 0.25s;
    zoom: 125%;
    text-transform: uppercase;
}

.btn-warning {
    // @extend .button;
}

.daytime-sheduler .daytime-days {
    font-weight: 400;
    width: 45px;
}

.daytime-sheduler .daytime-select {
    margin-right: 5px;
    min-width: 120px;
}

.daytime-sheduler .daytime-select .form-group {
    margin-bottom: 0;
    select {
        padding: 6px 8px;
    }
}

.daytime-sheduler .daytime-dayoff {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 5px;
    font-weight: 400;
}

.day-off-block {
    width: 80px;
    min-height: 22px;
    margin: 0 5px;
}

.last-block {
    width: 130px;
    min-height: 22px;
    margin: 0 5px;
}

.daytime-sheduler .daytime-dayoff .checkbox {
    margin: 0 0 0 7px;
}

.daytime-sheduler .daytime-dayoff .checkbox input[type='checkbox'] {
    cursor: pointer;
    margin-left: -16px;
}

.user-block-details input {
    min-width: 330px;
}

.user-form__holder .form-group {
    display: flex;
    flex-direction: column;
}

/*============== Animation Widget ===============*/

.animation-widget-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.animation-widget-wrapper .animation-widget-select {
    width: 250px;
    margin-right: 30px;
}

.animation-widget-wrapper .animation-widget-select select {
    width: 100%;
    color: #333;
}

.animation-widget-title {
    display: block;
    font-weight: 300;
    margin-bottom: 5px;
}

.clx-widget-example {
    width: 127px;
    height: 87px;
    background: #e51523;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 5px #b01721 solid;
    color: #ffffff;
    box-sizing: border-box;
    padding: 10px;
}

.clx-widget-example.clx-top-left {
    border-top-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.clx-widget-example.clx-top-right {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
}

.clx-widget-example span {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
}

.clx-widget-example p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: normal;
    margin: 5px auto 0;
    text-transform: uppercase;
    width: 97px;
    font-weight: 400;
    text-align: center;
    color: #fff;
}

/*============== Widget Code ===============*/

.code-textarea textarea {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    min-height: 92px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    padding: 6px 12px;
    resize: none;
}

.code-textarea .btn {
    margin-top: 0;
    margin-bottom: 0;
}

/*============== Leads Calendar styles ===============*/

.leads-chart-wrapper {
    margin-bottom: 15px;
}

.colors-legend-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.holder-colors-legend {
    display: flex;
    justify-content: flex-end;
}

.btn-right {
    margin-top: 0;
    margin-bottom: 0;
}

.colors-legend {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* max-width: 605px; */
    max-width: 800px;
}

.holder-legends-number {
    margin-right: 10px;
    width: 32%;
}

.holder-legends-number:first-child {
    width: 46%;
}

@media (max-width: 790px) {
    .colors-legend {
        flex-direction: column;
    }

    .holder-legends-number {
        width: 100%;
    }

    .holder-legends-number:first-child {
        width: 100%;
    }
}

@media (max-width: 950px) {
    .colors-legend-wrapper {
        flex-direction: column;
    }
}

.colors-legend .holder-legends-number > span {
    display: flex;
    font-size: 16px;
}

.colors-legend .holder-legends-number > span > span {
    margin-left: 5px;
}

.colors-legend .holder-legends-number > span:before {
    content: '';
    display: inline-block;
    width: 17px;
    height: 17px;
    min-width: 17px;
    border: 3px solid #eee;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 2px;
}

.colors-legend .holder-legends-number > span.legend-sg:before {
    background-color: #5cb85c;
}

.colors-legend .holder-legends-number > span.legend-sg > span {
    display: flex;
}

.colors-legend .holder-legends-number > span.legend-cm:before {
    background-color: #3174ad;
}

.colors-legend .holder-legends-number > span.legend-bpn:before {
    background-color: orange;
}

.colors-legend .holder-legends-number > span.legend-ic:before {
    background-color: #ef5350;
}

.colors-legend .holder-legends-number > span.legend-ch:before {
    background-color: #9999ff;
}

.colors-legend .holder-legends-number > span.legend-tu:before {
    background-color: #e6b800;
}

.colors-legend .holder-legends-number > span.legend-in:before {
    background-color: #3bc3ed;
}

.color-error {
    color: red;
}

.color-new {
    color: #5cb85c;
}

.leads-list-buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.leads-list-buttons-wrapper.flex-contetn__leads-list {
    display: flex;
    justify-content: space-between;
}

.leads-list-buttons-wrapper .btn {
    margin-bottom: 10px;
    margin-top: 0;
    margin-right: 15px;
    min-width: 143px;
}

.leads-list-buttons-wrapper .input-group {
    margin: 0;
}

#DateRangePickerContainer {
    width: 330px;
    margin-bottom: 5px;
}

#DateRangePickerContainer .dateTimeLabel {
    font-weight: 500;
}

@media screen and (max-width: 400px) {
    #DateRangePickerContainer {
        width: 360px;
    }
}

#DateRangePickerChildren input.form-control {
    background-color: #fff;
}

#DateRangePickerChildren .input-group-addon {
    cursor: pointer;
}

.leads-details-calendar .react-date-picker {
    margin-bottom: 15px;
    width: 100%;
}

.react-date-picker {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
    display: block;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 37px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.react-datepicker__input-container input:disabled {
    background-color: #eee;
}

.react-datepicker {
    font-size: 14px;
    font-family: Arial;
}

.react-datepicker__current-month {
    font-size: 16px;
    line-height: 16px;
}

.react-datepicker__month {
    margin: 10px;
}

.leads-details-calendar .react-date-picker .react-date-picker__wrapper,
.calendar-daysoff-form .react-date-picker .react-date-picker__wrapper {
    width: 100%;
}

.leads-details-calendar .form-group {
    margin-bottom: 0;
    margin-right: 5px;
    width: 100%;
}

.leads-details-calendar .react-date-picker__wrapper,
.calendar-daysoff-form .react-date-picker__wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 2.5px 10px;
    /* color: #555; */
}

.leads-details-calendar .react-date-picker--disabled {
    background-color: #eee;
    cursor: not-allowed;
}

.leads-details-calendar .react-date-picker--disabled button,
.leads-details-calendar .react-date-picker--disabled input {
    cursor: not-allowed;
}

.leads-details-calendar .react-date-picker--enabled {
    background-color: #fff;
}

.leads-details-calendar .react-date-picker__wrapper button {
    outline: none;
}

.calendar-modal-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.calendar-modal-row p {
    margin-bottom: 0;
    margin-left: 15px;
    color: #333;
}

.calendar-modal-row i {
    position: relative;
    top: -1px;
}

.calendar-questions {
    margin-top: 5px;
}

.calendar-questions ul {
    margin-top: 5px;
    padding-left: 20px;
    list-style: none;
}

.calendar-modal .modal-header {
    position: relative;
    padding-right: 30px;
}

.calendar-modal .close {
    outline: none;
    font-size: 44px;
    position: absolute;
    right: 15px;
    top: 5px;
    margin-top: 0;
    font-weight: 300;
}

.calendar-modal .close span {
    display: block;
}

a.rbc-show-more {
    font-size: 100%;
    font-weight: 400;
}

.rbc-day-slot .rbc-event {
    min-width: 17px;
    padding: 1px 5px;
}

.rbc-event.sg-calendar-event {
    background: #5cb85c;
    border-color: #ddd;
}

.rbc-event.bpn-calendar-event {
    background: orange;
    border-color: #ddd;
}

.rbc-event.ic-calendar-event {
    border-color: #ddd;
    background: #ef5350;
}

.rbc-event.ch-calendar-event {
    border-color: #ddd;
    background: #9999ff;
}

.rbc-event.tu-calendar-event {
    border-color: #ddd;
    background: #e6b800;
}

.rbc-event.in-calendar-event {
    border-color: #ddd;
    background: #3bc3ed;
}

.rbc-event.view-day {
    width: 20% !important;
}

.rbc-event.view-week {
    left: 0 !important;
    width: 100% !important;
}

.bpn-calendar-col {
    color: orange;
    font-weight: 400;
    white-space: normal;
}

.sg-calendar-col {
    font-weight: 400;
    white-space: normal;
    color: #5cb85c;
}

.cm-calendar-col {
    font-weight: 400;
    white-space: normal;
    color: #3174ad;
}

.ic-calendar-col {
    font-weight: 400;
    white-space: normal;
    color: #ef5350;
}

.ch-chat-col {
    font-weight: 400;
    white-space: normal;
    color: #9999ff;
}

.tu-textus-col {
    font-weight: 400;
    white-space: normal;
    color: #e6b800;
}

.in-insite-col {
    font-weight: 400;
    white-space: normal;
    color: #3bc3ed;
}

.rbc-calendar .rbc-header,
.rbc-calendar .rbc-date-cell.rbc-now {
    font-weight: 400;
}

.rbc-calendar .rbc-header {
    font-size: 16px;
}

.react-bs-table-bordered {
    overflow-x: auto;
}

.leads-table-wrapper .react-bs-table td {
    vertical-align: middle;
    border-top: 1px solid #ddd;
    height: auto;
    padding: 8px;
    white-space: normal;
}

.leads-table-wrapper .react-bs-table th {
    vertical-align: middle;
    height: auto;
    padding: 8px;
    margin: 0px 8px;
    white-space: normal;
}

.calendar-daysoff-form label {
    margin-top: 10px;
}

.left-indent-holder {
    padding-left: 0;
}

/*============== Holiday details ===============*/

.holiday-details-form .col-xs-12 {
    padding: 0;
}

.holiday-details-form .btn {
    margin-top: 0;
    margin-bottom: 0;
}

.holiday-details-form-title {
    font-weight: 400;
    margin-bottom: 10px;
}

.holiday-details-form-title::before {
    margin-left: 130px;
}

.holiday-details-form-title .col-xs-offset-1 {
    margin-left: 130px;
}

.holiday-details-content .left-indent {
    width: 130px;
}

.none-group-days {
    display: none;
}

@media screen and (max-width: 1199px) {
    .holiday-details-content {
        overflow: hidden;
        overflow-x: auto;
    }

    .holiday-details-content > div {
        width: 1000px;
    }
}

.negative-indent.has-error .prospect__control {
    border-color: #a94442;
}

/*============== Sidebar ===============*/

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
}

a[data-toggle='collapse'] {
    position: relative;
}

@media screen and (max-width: 1352px) {
    .leads-table-wrapper .react-bs-table {
        overflow: hidden;
        overflow-x: auto;
    }
}

@media (max-width: 1035px) and (min-width: 767px) {
    .min-height {
        min-height: 345px;
    }
}

@media (max-width: 1200px) {
    .daytime-form {
        overflow: hidden;
        overflow-x: auto;
    }

    .daytime-form > .daytime-form-inner {
        width: 1140px;
    }

    .leads-details-calendar .form-group {
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .daytime-form > .daytime-form-inner {
        width: 1000px;
    }
}

.se-container .se-wrapper-wysiwyg p {
    color: #000;
}

.se-wrapper-wysiwyg[contentEditable='false'] {
    background-color: #eee !important;
}

.wrap-table-group .area-holder {
    margin-bottom: 15px;
}

.wrap-table-group .area-holder {
    justify-content: space-between;
}

.wrap-table-group .area-holder table tbody tr:first-child td {
    border-top: 0;
}

.wrap-table-group .area-holder tbody tr td:first-child {
    width: 2%;
}

.wrap-table-group .area-holder tbody tr td:last-child {
    min-width: 250px;
}

.wrap-for-area .tatle-form__war thead tr th:nth-child(1) {
    width: 25%;
}

.wrap-for-area .tatle-form__war thead tr th:nth-child(2) {
    width: 25%;
}

.tatle-form__war thead tr th:nth-child(3) {
    width: 50%;
}

.wrap-table-group tbody tr td {
    min-width: inherit;
    max-width: inherit;
}

@media (max-width: 992px) {
    .wrap-table-group tbody tr {
        width: 100%;
    }
}

.rbc-toolbar {
    display: flex;
}

.rbc-toolbar .rbc-toolbar-label {
    font-weight: 400;
    zoom: 125%;
}

@media (max-width: 930px) {
    .rbc-toolbar {
        flex-direction: column;
    }

    .rbc-toolbar .rbc-toolbar-label {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }

    #sidebar.active {
        margin-left: 0;
    }

    .btn-sidebar {
        display: block;
    }

    .container {
        width: 100%;
    }

    .rbc-toolbar span {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .control-button-group {
        display: flex;
        flex-wrap: wrap;
    }

    .control-button-group .btn {
        margin-bottom: 0;
    }

    .react-bs-table {
        overflow: hidden;
        overflow-x: auto;
    }
}

.react-bs-container-footer {
    width: 100%;
    overflow-x: auto;
}

@media (max-width: 480px) {
    .fixed-controls .control-button-group .btn {
        min-width: 100px;
    }
}

/*
    ADDITIONAL DEMO STYLE, NOT IMPORTANT TO MAKE THINGS WORK BUT TO MAKE IT A BIT NICER :)
*/

#tcc-symbol {
    width: 20px;
    float: left;
    clear: both;
}

#sidebarCollapse {
    padding-right: 5px;
    padding-left: 5px;
    width: 30px;
}

#sidebarCollapse span {
    float: left;
    margin-top: 5px;
    font-size: 18px;
}

#sidebar {
    background: color('lightest-gray');
    color: color('text-color');
    transition: all 0.3s;
    position: relative;
    padding-bottom: 60px;
}

.navbar-selected-company {
    padding: 15px 15px;
    font-size: 0.9em;
    display: block;
    letter-spacing: 1.5px;
    border-bottom: 2px solid color('secondary_color');
    overflow: hidden;
    text-overflow: ellipsis;
}

.navbar-selected-company-sm {
    font-size: 0.8em;
    margin-left: 5px;
}

#sidebar .sidebar-active-role {
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    margin-bottom: 15px;
}

#sidebar .active-role-tag {
    margin-right: 10px;
    border-radius: 4px;
    background-color: color('lightest-gray');
    border: 2px solid color('gray');
    padding: 2px 4px;
    font-weight: 400;
    font-size: 19px;
    min-width: 40px;
    min-height: 35px;
    text-align: center;
}

#sidebar .active-role-name {
    margin-right: 5px;
}

#sidebar .sidebar-active-role .active-role-text {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}

#sidebar .sidebar-active-role .active-role {
    display: block;
}

#sidebar ul.components {
    padding: 0 0 20px 0;
}

#sidebar ul.components > li.dropdown-li,
#sidebar ul.components > li.border-active,
li.border-active {
    border-bottom: 2px solid color('secondary_color');
}

#sidebar ul.components > li:not(.dropdown-li):last-child {
    border-bottom: 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul > li a:not(.dropdown-item, .sidebar-cta-button) {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    min-height: 50px;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    width: 100%;
}

#sidebar ul li .clx-icon {
    font-size: 22px;
    margin-right: 10px;
}

.navbar-back {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    min-width: 50px;
    cursor: pointer;
}

.navbar-back .clx-icon {
    font-size: 22px;
    margin-right: 25px;
}

.navbar-back-text {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

#sidebar ul.components li a:hover:not(.sidebar-cta-button) {
    color: #fff;
    background: color('gray');
}

.support-button {
    border-bottom: 2px solid color('secondary_color');
}

#sidebar ul li.dropdown-li a:hover:not(.sidebar-cta-button) {
    background-color: color('gray');
}

#sidebar .nav-stacked > li + li {
    margin-top: 0;
}

#sidebar ul li.sidebar-dropdown-button.active > a {
    background: inherit;
    color: inherit;
}

#sidebar ul li.sidebar-dropdown-button.active > a:hover {
    color: #3a3a3a;
    background: #fff;
}

#sidebar ul li.active > a:not(.sidebar-cta-button),
#sidebar a[aria-expanded='true']:not(.sidebar-cta-button),
#sidebar a.active:not(.sidebar-cta-button) {
    color: #fff !important;
    background: color('gray') !important;
}

#sidebar a:not(.sidebar-cta-button) {
    cursor: pointer;
}

#sidebar .sidebar-submenu {
    background-color: color('lightest-gray');
}

#sidebar .sidebar-submenu > ul {
    padding-left: 0;
    letter-spacing: normal;
}

#sidebar .sidebar-submenu > ul a:not(.sidebar-cta-button) {
    background: inherit;
}

.sidebar-cta-button {
    width: 100%;

    // this is for the + symbol in the button
    &-plus {
        position: relative;
        top: 4px;
        font-size: 24px;
        line-height: 0;
        font-weight: bold;
        margin-left: -16px;
        margin-right: 16px;
    }
}

// Jared: Removing this for now, because it it causing conflicts with our redesign.
// TODO: Need to see what impact this has on other parts of the site
// ul ul a {
//     font-size: 0.9em !important;
//     padding-left: 50px !important;
//     background: #6d7fcc;
// }

.sidebar-submenu a:not(.sidebar-cta-button) {
    font-size: 0.9em !important;
    padding-left: 50px !important;
}

.sidebar-release {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 20px;
    font-size: 12px;
}

.sidebar-release a {
    text-decoration: underline;
}

#sidebar .select-li-2 {
    height: 64px;
}

#sidebar .select-li-2 .sidebar-select-wrapper .rbt {
    margin-bottom: 0;
}

.sidebar-select-wrapper .sidebar-select-inner {
    padding: 4px 16px 16px;
}

.sidebar-select-wrapper .select-company-wrapper {
    position: relative;
}

.lead-submission {
    min-width: 260px;
}

.sidebar-select-wrapper .select-company-wrapper::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 12px;
    color: #bbb;
    right: 12px;
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 8px;
    transform: rotate(135deg);
    vertical-align: top;
    width: 12px;
}

.sidebar-select-wrapper .sidebar-button {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 1px solid color('secondary_color');
    padding: 6px;
    outline: none;
}

.sidebar-select-wrapper .sidebar-button-wrapper {
    padding: 0 !important;
    font-size: inherit !important;
    min-height: auto !important;
}

.sidebar-select-wrapper .sidebar-button span {
    font-size: 32px;
    line-height: 18px;
    display: inline-block;
    vertical-align: top;
}

.sidebar-select-wrapper .rbt {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    height: 34px;
    color: #767676;
}

.sidebar-select-wrapper .rbt input {
    color: #767676;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    padding: 0 30px 0 15px;
    text-align: center;
}

.sidebar-select-wrapper .rbt input::placeholder {
    color: #767676;
}

.sidebar-select-wrapper .rbt .rbt-menu {
    border-radius: 0;
}

.sidebar-select-wrapper div.rbt ul.rbt-menu li a {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 14px !important;
    font-weight: 300;
}

div.rbt ul.rbt-menu li.clx-not-active a {
    color: #808080;
}

.dropdown-header {
    padding: 3px 15px;
    font-size: 14px;
    text-align: center;
}

.sidebar-select-wrapper div.rbt .rbt-highlight-text {
    font-weight: 400;
}

.sidebar-select-wrapper div.rbt .rbt-input-hint {
    display: none !important;
}

.holder-form-group {
    display: flex;
}

.form-holder-block .form-group {
    float: inherit;
}

.new-holder-box .text-right {
    display: inline-block;
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    margin: 0;
    min-width: 106px;
    white-space: nowrap;
    margin-left: 5px;
    padding: 6px 10px;
    line-height: 1.42857143;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    -o-transition: background 0.25s, color 0.25s;
    transition: background 0.25s, color 0.25s;
    -webkit-transition: background 0.25s, color 0.25s;
    zoom: 125%;
    text-align: center;
}

.new-holder-box .text-right:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
}

.span-button {
    cursor: pointer;
    text-transform: capitalize;
}

.clear-button {
    margin: 0 0 0 15px;
}

.field-group-tablet table {
    width: 100%;
}

.field-group-tablet table td {
    width: 100%;
}

.field-group-tablet table .btn-danger {
    min-width: 106px;
}

.fieldGroupNumberWrapper {
    text-align: center;
}

.fieldGroupNumberWrapper > input {
    width: calc(100% - 80px - 12px - 8px);
    margin: 0px 6px;
}

.fieldGroupNumberWrapper > * {
    display: inline-block;
    vertical-align: bottom;
    margin: auto;
}

.fieldGroupNumberWrapper button {
    width: 40px;
    height: 40px;
    padding: 0px;
    zoom: unset;
    font-size: 60px;
}

.fieldGroupNumberWrapper button div {
    width: 100%;
    height: 100%;
    line-height: 36px;
}

.view {
    display: inline-block;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.color-picker-preview {
    width: 15px;
    height: 15px;
}

.popover-picker-open {
    display: inherit;
    z-index: 999;
    position: absolute;
    right: 20px;
}

.popover-picker-closed {
    display: none;
}

.loader {
    height: 4px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: color('secondary_color');
    z-index: 9999;
}

.loader:before {
    position: absolute;
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #2980b9;
    animation: loading 2s linear infinite;
    z-index: 99999;
}

.genericOverlayWrapper {
    position: relative;
}

.genericOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.85);
}

.genericOverlay .iconWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.genericOverlay .glyphicon {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
    transform-origin: top;
}

.genericOverlay .animate .glyphicon {
    animation: pulsate 2s infinite alternate;
}

.genericOverlay .overlayText {
    text-align: center;
    font-size: 1.8rem;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.genericOverlay .overlayText.longText {
    font-size: 1.4rem;
}

.panel-body .genericOverlay {
    margin: -15px;
}

.image-cropper-sizes > span {
    display: block;
    margin-top: 8px;

    &:first-child {
        margin-top: 4px;
    }
}

.image-cropper {
    margin-bottom: 15px;
    display: flex;
}

.image-cropper .holder-crop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-crop-input {
    .button {
        line-height: 1;
        min-height: 43px;
        display: flex;
        align-items: center;
    }
}

.group-choose-file {
    display: block;
    position: relative;
    overflow: hidden;

    label {
        @extend .btn;
        @extend .btn-secondary;
    }

    .text-choose-file {
        display: inline-block;
        margin-left: 10px;
        zoom: 125%;

        &.selected {
            color: green;
        }
    }

    input.selected.add-text-file {
        color: green;
    }
}

.holder-crop {
    .btn-choose {
        margin-top: 10px;

        .button {
            margin-top: 0;
            &:hover {
                background-color: color('secondary_color_dark');
                border-color: color('secondary_color_dark');
            }
            label {
                padding: 0;
                background-color: transparent;
                &:hover {
                    background-color: transparent;
                }
            }
        }

        &.disabled label {
            cursor: default;
            opacity: inherit;
            color: rgba(#fff, 0.5);
        }

        label {
            display: inline-block;
            min-height: 46px;
            padding: 12px 20px;
            font-size: 14px;
            font-weight: bold;
            border-radius: 4px;
            margin: 0;
            text-align: center;
            cursor: pointer;
        }
    }
}

.image-cropper img {
    max-height: 133px;
    margin-right: 10px;
}

.holder-wrap-widget .preview-widget-wrapper {
    padding-bottom: 0;
}

.preview-widget-wrapper {
    .clx-calendar-available-times {
        .clx-available-time-item {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 5px;
                top: 7px;
                width: 16px;
                height: 16px;
                border: 1px solid;
                border-radius: 100%;
                background: #fff;
            }
        }
    }
}

.holder-wrap-widget .preview-widget-container {
    margin: 0 0 20px;
}

.widget-holder-image {
    min-width: 133px;
    max-height: 133px;
    border: 2px solid #c2c2c2;
    display: block;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
}

.box-widget {
    width: 24px;
    height: 24px;
}

.box-widget img {
    width: 24px;
    height: 24px;
}

.wrap-widget-hold {
    width: 127px;
    height: 50px;
    background-color: rgb(217, 28, 40);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    right: -35px;
    zoom: 0;
    padding: 7px 15px;
}

// Note: body required for specificity to override
.modal-crop-input {
    label {
        // @extend .button-accent;
        display: inline-block;
        width: 120px;
        margin-top: 8px;
    }

    &.disabled label {
        // @extend .button-accent;
        // @extend .button-disabled;
    }

    .button {
        margin-top: 8px;
        font-weight: 400;
        padding: 10px 0;

        label {
            font-size: 14px;
            margin: 0;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
            background: transparent;
            cursor: pointer;
            zoom: 125%;
        }
    }
}

.modal-crop-input input {
    opacity: 0;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    position: absolute;
}

/* //  Text US Now block  // */

.holder-message-box .chat-list {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px 5px;
    max-height: 475px;
}

.holder-message-box .control-label {
    font-size: 16px;
    font-weight: 400;
}

.sent-message .block-message .sms-text {
    padding: 15px 20px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 80px;
    color: #3b3643;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
}

.sent-message label {
    font-size: 14px;
    color: #a4878b;
}

.color-message__remote {
    margin-top: 100px;
}

.color-message__conversion {
    margin-top: 100px;
}

@media screen and (max-width: 991px) {
    .color-message__remote {
        margin-top: 0;
    }

    .color-message__conversion {
        margin-top: 0;
    }
}

.color-message__conversion:first-child {
    margin-top: 0;
}

.color-message__remote .sms-text {
    background: #d9d9d9;
}

.color-message__conversion .sms-text {
    background: #9bbddb;
}

.message-info {
    padding: 15px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    margin: 0 0 30px;
    border-radius: 4px;
}

.text-display-block .block-indent__padding form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.text-display-block .block-indent__padding form .indent-outside {
    width: 100%;
}

.xsmall-btn {
    min-width: 300px;
    width: 50%;
    font-weight: lighter;
    margin-right: 0;
}

.text-display-block {
    display: flex;
    justify-content: flex-end;
}

.text-display-block .btn-toolbar {
    display: flex;
    justify-content: flex-end;
}

.ReactCrop {
    margin-top: 15px;
    margin-right: 15px;
}

@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }

    50% {
        width: 30%;
    }

    70% {
        width: 70%;
    }

    80% {
        left: 50%;
    }

    95% {
        left: 120%;
    }

    to {
        left: 100%;
    }
}

@keyframes pulsate {
    from {
        transform: scale(0.4);
    }

    to {
        transform: scale(1);
    }
}

@keyframes glow {
    from {
        box-shadow: 0px 0px 10px #22aad4;
    }

    to {
        box-shadow: unset;
    }
}

.jumbotron h1 {
    font-size: 30pt;
    font-weight: bold;
}

.jumbotron .glyphicon {
    font-size: 30pt;
}

.jumbotron h1 div {
    margin-bottom: 20px;
}

.jumbotron h1 .scoreboard-number {
    color: red;
    font-size: 31pt;
    font-weight: bold;
}

.active-application-checkbox {
    float: left;
    margin-top: 22px;
    margin-left: 23px;
}

.active-application-checkbox input {
    transform: scale(1.2);
}

.income-calculator-form input:not([type='checkbox']) {
    width: 100%;
    display: block;
}

.input-row input {
    margin-bottom: 10px;
}

.input-row .input-group {
    margin-top: 0;
    margin-bottom: 0;
}

/* // TODO: Changed styles in select add shadow. And add in prospect-questions padding  */

.prospect-padding .new-indent .col-xs-12 {
    padding: 0;
}

.indent {
    padding: 0;
}

.css-react-select {
    outline: none;
    margin: 0 0 15px;
}

.css-react-select .prospect__control--is-focused {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.css-react-select .prospect__option--is-disabled {
    color: #888888;
}

.css-react-select > div > div > div:last-child {
    padding-bottom: 0;
    padding-top: 0;
}

.css-react-select > div > div > div > div {
    background-color: inherit;
    color: inherit;
}

.css-react-select > div > div > div > div:hover:not(.prospect__option--is-disabled) {
    cursor: pointer;
    background-color: #2684ff;
    color: hsl(0, 0%, 100%);
}

@media screen and (max-width: 768px) {
    .income-table-wrapper {
        overflow-x: auto;
    }

    .income-table-wrapper .income-table {
        width: 800px;
    }
}

.image-editable {
    cursor: pointer;
}

.calendar-days-table-wrapper {
    float: left;
    width: 100%;
}

.calendar-days-row {
    margin-bottom: 5px;
}

.calendar-days-table .col-xs-1 {
    width: 14.28%;
}

.calendar-days-table .col-xs-1 label {
    display: none;
}

.calendar-days-table .title-row {
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 850px) {
    .calendar-days-table-wrapper {
        overflow-x: auto;
    }
}

.scoreboard-widget .jumbotron {
    padding-left: inherit;
    padding-right: inherit;
}

.scoreboard-widget .total-lead-count {
    float: right;
}

div.registration-link {
    padding: 12px;
}

div.registration-link span {
    font-family: monospace;
    margin: 20px;
    border-color: black;
    border-style: groove;
    padding: 4px;
}

@-o-keyframes fadeIt {
    0% {
        background-color: #ff0000;
    }

    5% {
        background-color: #eeeeee;
    }

    10% {
        background-color: #ff0000;
    }

    15% {
        background-color: #eeeeee;
    }

    20% {
        background-color: #cc0000;
    }

    100% {
        background-color: #eeeeee;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #ff0000;
    }

    5% {
        background-color: #eeeeee;
    }

    10% {
        background-color: #ff0000;
    }

    15% {
        background-color: #eeeeee;
    }

    20% {
        background-color: #cc0000;
    }

    100% {
        background-color: #eeeeee;
    }
}

.scoreboard-widget .flash {
    background-image: none !important;
    -o-animation: fadeIt 3s ease-in-out;
    animation: fadeIt 3s ease-in-out;
}

.scoreboard-widget .company-by-lead-count {
    font-size: 17pt;
    text-align: left;
    color: red;
}

.qualification-qualified,
.contract-ok {
    color: green;
}

.qualification-unqualified,
.contract-error {
    color: red;
}

.contract-warning {
    color: orange;
}

input[type='checkbox']:not(.lc-input-switch-input) {
    transform: scale(1.5);
}

input.lc-input-switch-input[type='checkbox'] {
    margin-top: 0;

    &:focus {
        outline: none;
    }
}

select,
textarea:not(.MuiInputBase-input) {
    zoom: 125%;
}

.link-box {
    width: 100%;
}

#sidebar input,
#sidebar button,
#sidebar select {
    zoom: 100%;
}

.toggle-button-on {
    background-color: #84ef89;
}

.toggle-button-off {
    background-color: #ef8484;
}

.btn-success {
    // @extend .button;
}

.btn-default-gray {
    // @extend .button;
    background-color: #606060;
    color: #fff;
    &:hover {
        color: #fff;
        background-color: #4d4d4d;
    }
}

.btn-warning {
    // @extend .button;
}

.btn-danger {
    // @extend .button;
    background-color: #d9534f;
    border-color: #d43f3a;
    &:hover {
        background-color: #c9302c;
        border-color: #ac2925;
        color: #fff;
    }
}

button[type='submit'] .submit-icon {
    display: inline;
    margin-left: 10px;
}

.maximum-rents-errors {
    margin: 8px 15px -8px 15px;
}

.input-group .form-group.has-error input,
.input-group .input-group-addon.has-error {
    background-color: rgba(255, 0, 0, 0.2);
    border: 1px solid red;
}

span.text.has-error {
    color: red;
}

/* TO DO // This class => .negative-indent gives a negative margin on this element. To avoid large indentation */

.negative-indent {
    margin: 0 -15px;
}

.panel-bode__holder .form-group {
    margin-bottom: 0;
}

.inner__wrapper {
    display: flex;
    margin: 0 30px;
    flex-wrap: wrap;
}

.no-padding .form-group {
    padding-left: 0px;
    padding-right: 0px;
}

//  SMS messages list

.col-holder-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    button {
        margin: 0 5px 10px !important;
    }
}

@media (max-width: 1550px) {
    .col-holder-btns {
        zoom: 90%;
    }
}

@media (max-width: 1420px) {
    .col-holder-btns {
        zoom: 80%;
    }
}

@media (max-width: 1350px) {
    .inner__wrapper {
        margin: 0 20px;
    }
}

@media (max-width: 991px) {
    .col-holder-btns {
        zoom: 80%;
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 768px) {
    .col-holder-btns {
        zoom: 100%;
    }
}

@media (max-width: 375px) {
    .inner__wrapper {
        zoom: 88%;
    }
}

.lead-state__panel-holder {
    margin: 0 30px;
}

.lead-state__panel-holder label {
    color: #fff;
}

@media (max-width: 1350px) {
    .lead-state__panel-holder {
        margin: 0 20px;
    }
}

@media (max-width: 991px) {
    .compact .next-state {
        display: inherit !important;
    }
}

@media (max-width: 767px) {
    .compact .next-state {
        display: none !important;
        width: inherit;
    }
}

.col-block > div {
    margin-bottom: 15px;
}

.panel-bode__holder .col-holder-block {
    margin-bottom: 15px;
}

.best-price-holder .col-holder-block {
    width: 33.33%;
}

@media screen and (max-width: 900px) {
    .best-price-holder .col-holder-block {
        width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .best-price-holder .col-holder-block {
        width: 100%;
    }
}

.col-holder__details {
    min-width: 115px;
}

.input-group .form-group.has-error input {
    border-left: 0px;
}

.editable-household {
    background-color: rgba(0, 0, 255, 0.2);
    border: 1px solid blue;
}

.draggable-widgets > div > div {
    display: flex;
    align-items: center;
}

.draggable-widgets > div:last-child {
    margin-right: 0 !important;
}

.draggable-widgets > div > div > span.clxicon {
    margin-right: 5px;
}

.draggable-widgets > div > div:last-child {
    margin-bottom: 0 !important;
}

.draggable-widgets-titles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.draggable-widgets-titles h4 {
    width: 300px;
    margin-right: 20px;
}

.draggable-widgets-titles h4:last-child {
    margin-right: 0;
}

#widgetbar_expanded {
    position: relative;
    margin-left: -10px;
    top: 2px;
    margin-right: 7px;
}

.utm-referrer-table {
    word-break: break-all;
}

.utm-referrer-table .utm-referrer-table-date {
    width: 160px;
}

.draggable-widgets {
    display: flex;
    flex-wrap: wrap;
}

.form-group.no-label {
    padding-top: 10px;
}

.draggable-widget-col {
    background: #eee;
    padding: 8px;
    width: 300px;
    margin-right: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-bottom: 15px;
}

.draggable-widget-col-drag-over {
    background: lightblue;
    padding: 8px;
    width: 300px;
    margin-right: 20px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    margin-bottom: 15px;
}

.buttons-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 43px;
}

.buttons-bar i {
    margin-right: 15px;
    cursor: pointer;
    color: #a5a5a5;
}

.floorplan-table .input-row {
    min-height: 55px;
    border-top: 1px solid #ddd;
    text-align: center;
}

.floorplan-table .input-row:first-child {
    border-top: 0;
}

.floorplan-table .input-row:not(:first-child) {
    display: flex;
    align-items: center;
}

.floorplan-table .input-row input {
    margin-bottom: 0;
}

.floorplan-table .maximum-title {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.floorplan-table .input-group input {
    height: 100%;
}

@media (max-width: 1200px) {
    .floorplan-table {
        overflow-x: auto;
    }

    .floorplan-table > .panel {
        width: 1036px;
    }

    .floorplan-table .income-table-wrapper .income-table {
        width: unset;
    }

    .floorplan-table .income-table-wrapper {
        overflow-x: unset;
    }
}

.table-responsive th {
    font-weight: 300;
}

@media (max-width: 800px) {
    .table-holder-block .table {
        width: auto;
    }

    .table {
        width: auto;
    }
}

.floorplan-table .input-row > .col-xs-2:first-of-type {
    text-align: left;
}

/* ======== Chat Interface =========== */

.smsChatMain .progress-bar {
    -webkit-transition: width 0.2s ease-out;
    transition: width 0.2s ease-out;
}

.smsChatMain .inboxSelector li a {
    font-size: 1.8rem;
    border: 1px solid #ddd;
    font-weight: 400;
}

.smsChatMain .inboxSelector .nav-tabs li.active a {
    background-color: #22aad4;
}

.threadContent {
    min-width: 320px;
}

.list-group::-webkit-scrollbar {
    width: 10px;
}

.list-group::-webkit-scrollbar-track {
    background-color: #000;
}

.list-group::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: 2px solid #000;
}

.list-group {
    height: 480px;
    overflow-y: auto;
}

.active-height {
    height: auto;
}

@media (max-width: 1025px) {
    .threadContent .threadMenuBar {
        padding: 8px 10px 0;
    }
}

@media (max-width: 768px) {
    .threadList .list-group .row {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
    }

    .first-col-number .col-xs-7 {
        padding: 0;
    }

    .threadList .list-group .meta {
        width: 100%;
    }

    .threadList .list-group .col-narrow {
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 480px) {
    .threadContent {
        min-width: 275px;
    }

    .top-holder .inboxSelector {
        min-width: 275px;
    }
}

.chat-list {
    min-height: 250px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
}

.chat-wrap-holder .chat-list {
    max-height: 425px;
}

.chat-wrap-holder {
    min-width: 320px;
}

@media (max-height: 820px) {
    .chat-wrap-holder .chat-list {
        min-height: 190px;
        max-height: 190px;
    }

    .smsChatMain {
        padding-left: 0;
        padding-right: 0;
    }
}

.container-margin {
    margin-bottom: 0;
}

@media (max-width: 480px) {
    .chat-wrap-holder {
        min-width: 300px;
    }
}

.chat-list > .container {
    margin: 10px 0px 30px 0px;
}

.chat-list .highlight,
.chat-list .highlight .chat-content::before {
    animation: fadeIt 3s;
    background-clip: content-box;
}

.sms-composer .container {
    margin-bottom: 15px;
}

.chat-lip {
    margin-top: 12px;
    font-size: smaller;
}

.chat-content {
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
    position: relative;
}

.chat-content img {
    max-width: 100%;
}

.chat-content .imageWrapper .caption {
    margin-top: 8px;
    text-align: center;
}

.chat-content .message {
    white-space: pre-wrap;
    text-align: left;
}

.chat-bubble.error .message {
    opacity: 0.5;
}

.chat-bubble .chat-content::before {
    content: '';
    width: 0px;
    height: 0px;
    border: 20px solid transparent;
    position: absolute;
    top: 0px;
}

.chat-bubble.outgoing .chat-content::before {
    border-top-color: #3174ad;
    right: -20px;
}

.chat-bubble.outgoing .chat-content {
    background-color: #3174ad;
    color: white;
    margin-right: 15px;
}

.chat-bubble.incoming .chat-content::before {
    border-top-color: #5cb85c;
    left: -20px;
}

.chat-bubble.incoming .chat-content {
    background-color: #5cb85c;
    color: white;
}

.chat-bubble .error-msg {
    color: #ef5350;
}

.chat-bubble .error-msg .glyphicon {
    margin-right: 10px;
}

.threadMenuBar {
    margin-top: -10px;
    margin-bottom: 5px;
    background-color: #3174ad;
    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .holder-info-details {
        margin: 0 5px 0;
    }
}

.threadMenuBar button .glyphicon {
    margin-right: 8px;
}

@media (max-width: 768px) {
    .threadMenuBar button .glyphicon {
        margin-right: 0;
    }

    .threadMenuBar button {
        min-width: 50px;
    }
}

.archiv-btn-box button .glyphicon {
    margin-right: 8px;
}

@media (max-width: 1130px) {
    .archiv-btn button {
        width: 160px;
    }

    .keep-btn button {
        width: 160px;
    }
}

@media (max-width: 768px) {
    .keep-btn button {
        min-width: inherit;
        width: 50px;
        height: 34px;
    }

    .archiv-btn button {
        width: inherit;
    }
}

@media (max-width: 460px) {
    .keep-btn button {
        width: 50px;
    }

    .archiv-btn button {
        min-width: 50px;
    }
}

.threadMenuBar button .buttonText {
    display: inline;
    margin: 0px;
    padding: 0px;
    float: none;
}

.threadMenuBar .keepUnread {
    background-color: #5cb85c;
    border-color: #5cb85c;
    color: white;
    &:hover {
        color: #fff;
        background-color: #449d44;
        border-color: #398439;
    }
}

.control-sub {
    button {
        font-weight: normal;
    }
}

.btn-weight {
    font-weight: 400;
    zoom: 125%;
    padding: 9px 12px;
    height: 34px;
    border-radius: 4px;
}

.threadList .list-group .meta {
    font-size: 1rem;
}

.threadList .list-group .thread.unread:not(.selected) {
    color: #ef5350;
}

.threadList .list-group .thread {
    background-color: black;
    color: white;
}

.threadList .list-group .thread.selected {
    background-color: #22aad4;
    box-shadow: 0px 2px 10px 2px #22aad4;
}

.threadList .thread .unreadCountWrapper {
    padding: 0px;
}

//  SMS messages left block message

.circle-unread-messages {
    font-size: 14px;
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontal-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.holder-unread-messages {
    display: flex;
    align-items: center;
    min-width: 45px;
    margin: 0;
    .circle-unread-messages {
        width: 20px;
        height: 20px;
        zoom: 125%;
        font-size: 10px;
    }
}

.widgetMetricsSpinner {
    height: 250px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-template-bpn .modal-dialog {
    width: 1250px;
}

.widget-position-adjustments {
    margin-bottom: 25px;
}

.widget-position-adjustments input {
    width: 100%;
}

.inline-error {
    display: inline-block;
}

.featureFlags {
    width: 100%;
}

.featureflagAlert {
    top: 10px;
    right: 30px;
    z-index: 20;
}

.features {
    width: 100%;
}

.featuresAlert {
    top: 10px;
    right: 30px;
    z-index: 20;
}

#lead_to_matchback_report,
#matchback_detail_report {
    min-height: 200px;
    border: black 1px;
    height: auto;
    align-items: top;
    margin: 0 auto;
    padding: 15px 0;
}

#lead_to_matchback_report h1,
#matchback_detail_report h1 {
    text-align: center;
    font-weight: 500;
    font-size: 32px;
}

#lead_to_matchback_report table {
    text-align: center;
    margin: auto;
}

#lead_to_matchback_report th,
#lead_to_matchback_report td {
    padding: 0px 12px 4px 12px;
    background-color: none;
    font-size: 32px;
    text-align: center;
}

#lead_to_matchback_report td {
    color: color('secondary_color');
    font-weight: 500;
}

#lead_to_matchback_report .ltl_right {
    text-align: right;
    font-weight: 100 !important;
    font-size: 18px !important;
    color: #000000;
}

#lead_to_matchback_report .ltl_header th {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: color('secondary_color');
}

.chartPdfButton {
    padding-bottom: 20px;
}

.widgetCharts {
    min-width: 800px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .page-header {
        width: 100%;
    }
}

#widgetChartDetailsFooter div {
    font-size: 18px;
}

.widgetChartDetails {
    margin: auto;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.widgetChartDetails span.headerNotes div {
    width: 80%;
    margin: auto;
}

.widgetChartDetails span.headerNotes {
    font-size: 16px;
    font-weight: 100;
    color: #03828b;
}

.widgetChartDetails div.leadCount {
    position: relative;
    display: inline-grid;
    color: color('secondary_color');
    background-color: #efefef;
    margin: 16px;
    padding: 20px;
    width: 150px;
    border: 1px solid #000000;
    line-height: 16px;
}

.widgetChartDetails div.leadCount div {
    height: 32px;
}

.widgetChartDetails div.leadCount div.moduleName {
    font-size: 18px;
    color: #000;
    height: 32px;
}

.chartWrapper {
    position: relative;
    margin: 20px 0;
    @media (max-width: 1050px) {
        width: 100%;
    }
}

.comboChart {
    width: 100%;
}

.chartWrapper #utm-category-counts-chart {
    display: inline-block;
}

.chartWrapper div.chartFilter {
    margin: auto;
    display: inline-block;
}

.lead-journey-details-table-tags div {
    border-radius: 5px;
    display: inline-block;
    color: #000000;
    padding: 5px 8px;
    margin: 5px 8px;
    background-color: #8fd1fa;
    border: 1px solid #000000;
}

.lead-journey-details-table-tags div span {
    margin-right: 6px;
    font-weight: 500;
}

.lead-journey-details-table-tags div.firsttouch {
    color: #ffffff;
    background-color: #009aa5;
    font-weight: 500;
}

.lead-journey-details-table-tags div.conversion {
    color: #ffffff;
    background-color: #b61c1c;
    font-weight: 500;
}

.lead-journey-details-table-tags div.conversion span {
    font-weight: 500;
}

.lead-journey-details-table-tags div.device {
    background-color: #7ff59b;
}

.lead-journey-details-table-tags div.referrer {
    background-color: #fec0a9;
}

.lead-journey-details-table-tags div.matchback {
    background-color: #33fffc;
}

.lead-journey-details-table-tags div.log_message {
    background-color: #ffffff;
}

.lead-journey-details-table-tags div.interactions {
    background-color: #fff04a;
}

/* ======= Lead Nurturing ======== */

.leadStatePickPanel .buttonWrapper {
    text-align: center;
    padding-top: 12px;
}

.leadStateSelector.fancy {
    border: 1px solid black;
    max-width: 250px;
}

.leadStateSelector.fancy .list-group {
    height: inherit;
}

.leadStateSelector .header {
    padding: 8px;
    text-align: center;
}

.leadStateSelector .title {
    font-size: 2rem;
    font-weight: 400;
}

.leadStateSelector .collapseButton {
    zoom: 1;
    float: left;
    padding: 0px 0px;
    font-size: 5rem;
    line-height: 2.5rem;
}

.leadStateSelector .list-group button {
    background-color: #22aad4;
    color: white;
}

.leadStateSelector .list-group button.active {
    font-weight: 400;
    background-color: #286090;
}

.leadStateSelector .list-group {
    margin-bottom: 2px;
}

.leadNurtureMain .genericOverlay {
    z-index: 1;
}

.leadNurtureMain .nurtureStep .block {
    border: 1px solid black;
    margin: 4px 0px;
    padding: 5px;
}

.leadNurtureMain .editToolbar .saveButton {
    animation: glow 1s infinite alternate;
}

.leadNurtureMain .nurtureStep .previewButtonWrapper {
    display: inline-block;
    margin-left: 15%;
}

.leadNurtureMain .nurtureStep .previewButtonWrapper button {
    margin-left: 15%;
}

.leadNurtureMain .nurtureStepPreview .chat-list {
    height: unset;
    overflow-y: hidden;
}

.leadNurtureMain .max_execution_explainer {
    font-size: x-large;
    position: absolute;
    top: 0px;
    right: 8px;
}

/* Create a horizontal scroll container */

.leadNurtureMain .actionsWrapper.row {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: rgba(0, 174, 239, 0.1);
    padding: 6px;
    margin: 10px 0px;
    border-radius: 8px;
    border-top: 4px double color('secondary_color');
    border-bottom: 4px double color('secondary_color');
    border-top-width: 80%;
    box-shadow: 0px 2px 10px 2px rgba(51, 122, 183, 0.3);
}

.leadNurtureMain .smsActionsRowWrapper .supportsSmsActionsOverlay {
    width: 108%;
    margin: 0% -4%;
    z-index: 2;
    background-color: white;
}

.leadNurtureMain .nurtureStepWrapper {
    min-width: 300px;
    display: inline-block;
    vertical-align: middle;
    float: initial;
    white-space: normal;
    padding: 0px 5px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    margin-right: 8px;
}

.leadNurtureMain .nurtureStepWrapper.addButtonWrapper {
    min-height: 418px;
    padding: 0px;
    cursor: pointer;
    background-color: #5bc0de;
    color: black;
    border: 3px solid#363636;
    box-shadow: 0px 0px 6px #286090;
}

.leadNurtureMain .nurtureStepWrapper.addButtonWrapper.disabled {
    cursor: not-allowed;
    background-color: #9e9e9e;
    color: rgba(0, 0, 0, 0.5);
}

.leadNurtureMain .nurtureStepWrapper .deleteButton {
    width: 100%;
    text-align: center;
}

.leadNurtureMain .nurtureStepWrapper .deleteButton > * {
    display: inline-block;
    margin-right: 10px;
}

.leadNurtureMain .reorder {
    border: 1px solid black;
    text-align: center;
}

.leadNurtureMain .reorder button {
    width: 100%;
    max-width: 80px;
    padding: 5px 0px;
}

.leadNurtureMain .reorder button > span {
    font-size: 28px;
}

.addNurtureStepButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.addNurtureStepButton .text {
    font-size: 2.5rem;
}

.addNurtureStepButton .plus {
    font-size: 100px;
}

.leadNurtureMain .nurtureStepWrapper .mainText textarea {
    min-height: 120px;
}

.leadNurtureMain .nurtureStepWrapper .container,
.leadNurtureMain .nurtureStepWrapper .form-group {
    margin-bottom: 0px;
}

.leadNurtureMain .nurtureStepWrapper textarea.mainText {
    min-height: 130px;
}

.lead-journey-details-table-tags div.message {
    color: #ffffff;
    background-color: #7e3bc9;
}

.thread-header-search-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px 0;
}

.thread-header-search {
    flex-grow: 1;
    margin: 0 10px 0 0;
}
.leads-counts {
    display: flex;
    justify-content: flex-end;
    padding: 8px 0;
    min-height: 42px;
    font-size: 16px;
    .count-row {
        display: block;
        padding: 2px 0;
        margin-left: 12px;
        text-align: end;
        &:first-child {
            font-weight: 500;
        }
        .text-leads-count {
            display: inline-block;
            font-weight: normal;
            margin-left: 5px;
            margin-right: 10px;
            @media (max-width: 460px) {
                margin-left: 0;
            }
            &.last {
                margin-right: 0px;
            }
        }
    }
}

.react-bs-table .table-bordered > tbody > tr.row-selected {
    color: green;
}

/* Modal Table */

.modal-table {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    overflow-x: scroll;
}

@media (max-width: 991px) {
    .modal-table {
        display: block;
    }
}

.modal-table thead tr th {
    border: 0;
}

.modal-table thead tr th:nth-child(1) {
    width: 50%;
}

@media (max-width: 991px) {
    .modal-table thead tr th:nth-child(3),
    .modal-table tbody tr td:nth-child(3) {
        min-width: 120px;
    }
}

@media (max-width: 570px) {
    .modal-table thead tr th:nth-child(2),
    .modal-table tbody tr td:nth-child(2) {
        min-width: 120px;
    }
}

.modal-table tbody tr td {
    vertical-align: middle;
}

.modal-table tbody tr td .btn {
    margin-top: 0;
    margin-bottom: 0;
}

.indent-outside__more {
    margin-left: -30px;
    margin-right: -30px;
}

/* Mini Preview */
.clx-widget-menu-wrapper.skin1.animated.mini .more-btn {
    display: none;
}

// Preview chevroon in mini widget Wallet

.preview-widget-wrapper .clx-widget-menu-wrapper .chevron-mini-widget__close {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    zoom: 1;
    border: 1px solid #cccccc;
    background: #fff;
}

.preview-widget-wrapper .clx-widget-menu-wrapper .chevron-mini-widget__close::before {
    content: '';
    position: absolute;
    background: #ccc;
    width: 12px;
    height: 1px;
    transform: rotate(45deg);
    top: 18px;
    left: 17px;
}

.preview-widget-wrapper .clx-widget-menu-wrapper .chevron-mini-widget__close::after {
    content: '';
    position: absolute;
    background: #ccc;
    width: 12px;
    height: 1px;
    transform: rotate(-45deg);
    top: 18px;
    left: 9px;
}

.clx-widget-menu-wrapper.clx-top-left .chevron-mini-widget__close {
    position: absolute;
    bottom: -25px;
    right: -25px;
    transform: rotate(180deg);
}

.clx-widget-menu-wrapper.clx-top-right .chevron-mini-widget__close {
    position: absolute;
    bottom: -25px;
    left: -25px;
    transform: rotate(180deg);
}

.clx-widget-menu-wrapper.clx-bottom-left .chevron-mini-widget__close {
    position: absolute;
    right: -20px;
    top: -30px;
}

.clx-widget-menu-wrapper.clx-bottom-middle .chevron-mini-widget__close {
    position: absolute;
    left: -20px;
    top: -30px;
}

.clx-widget-menu-wrapper.clx-bottom-right .chevron-mini-widget__close {
    position: absolute;
    left: -20px;
    top: -30px;
}

.clx-widget-menu-wrapper {
    &.clx-top-left {
        .clx-widget-menu {
            border-top-left-radius: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 0;
        }
    }

    &.clx-bottom-left {
        .clx-widget-menu {
            border-top-left-radius: 0;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 10px;
        }
    }

    &.clx-bottom-middle {
        .clx-widget-menu {
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

// Preview chevroon in mini widget Defauit

.clx-widget-menu {
    width: 112px;
    .clx-widget-single-item {
        padding: 5px 0;
        .clx-widget-title {
            width: 91px;
        }
    }

    //// Defauit
    &.clx-bottom-middle {
        .clx-widget-menu {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
        }
    }

    &.clx-bottom-left {
        .clx-widget-menu {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.clx-top-left {
        .clx-widget-menu {
            border-top-left-radius: 0px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 0;
        }
    }

    &.clx-bottom-left,
    &.clx-bottom-middle {
        background: black;
        .chevron-mini-widget__close-btn {
            right: -25px;
            top: -25px;
        }
    }

    &.clx-bottom-left,
    &.clx-bottom-middle,
    &.clx-top-left {
        background: black;
        .chevron-mini-widget__close-btn {
            transform: rotate(90deg);
        }
    }

    &.clx-bottom-right {
        .chevron-mini-widget__close-btn {
            left: -25px;
            top: -25px;
            transform: rotate(-90deg);
        }
    }

    &.clx-top-left {
        .chevron-mini-widget__close-btn {
            right: -25px;
            bottom: -25px;
        }
        .mini-widget_default {
            border-bottom-right-radius: 10px;
        }
    }

    &.clx-top-right {
        .chevron-mini-widget__close-btn {
            left: -25px;
            bottom: -25px;
            transform: rotate(-90deg);
        }
        .mini-widget_default {
            border-bottom-left-radius: 10px;
        }
    }

    .chevron-mini-widget__close-btn {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        position: absolute;
        background: #fff;
        border: 1px solid #e51523;
        color: #e51523;
        zoom: 1;
        &::before {
            content: '';
            position: absolute;
            background: #000;
            width: 9px;
            height: 1px;
            transform: rotate(45deg);
            top: 12px;
            left: 12px;
        }
        &::after {
            content: '';
            position: absolute;
            background: #000;
            width: 9px;
            height: 1px;
            transform: rotate(-45deg);
            top: 12px;
            left: 6px;
        }
    }

    .mini-widget_default {
        transform: translate(25px, 5px);
    }
}

/* User Consent */
.user-info-consent {
    color: #fff;
    margin-right: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 120px;
}

.user-info-consent .buttonText {
    white-space: normal;
    margin-right: 0;
    padding: 5px;
    text-align: center;
}

.col-holder-btns .btn-primary {
    margin-right: 10px;
}

@media (max-width: 1566px) {
    .export-btn {
        margin-left: 0;
    }
}

@media (max-width: 700px) {
    .col-holder-btns .btn-primary {
        margin-right: 0;
    }
}

@media (max-width: 560px) {
    .col-holder-btns .archiv-btn {
        margin-right: 0;
    }
}
@media (max-width: 1270px) {
    .user-info-consent {
        margin-left: 0;
    }
}

.icon-holder-group {
    .form-group {
        .checkbox,
        .radio {
            margin-top: 0;
        }
    }
}

@media (max-width: 1399px) {
    .icon-holder-group {
        .form-group {
            .checkbox,
            .radio {
                margin: 0 7px 10px;
            }
        }
    }

    .icon-holder-group {
        .form-group {
            margin-top: 0;
        }
    }
}
.flex-block-links {
    display: flex;
}

.like-bar_links-holder .flex-block-links .row-links {
    margin-right: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.flex-block-label {
    width: 100%;
    margin-right: 15px;
}

.flex-block-links button {
    color: #555;
    height: 32px;
    padding: 3px 10px 5px;
    margin-top: 20px;
    opacity: 1 !important;
    color: #fff;
}

.message-notification-holder {
    .btn-default[disabled] {
        background-color: #f5f6fa;
        border: 1px solid #f5f6fa;
        &:hover {
            background-color: #f5f6fa;
            border: 1px solid #f5f6fa;
        }
    }
}

@media (max-width: 380px) {
    .flex-block-links button {
        margin-top: 36px;
    }

    .flex-block-links:last-child button {
        margin-top: 0;
    }
}

.lead_notification-hold {
    .notification-override-panel {
        .panel-body {
            padding: 15px 0;
        }
    }
}

.lead_notification-hold {
    .notification-override-panel {
        .panel-body {
            padding: 15px 0;
        }
    }
}

.number-control-form {
    .form-group {
        input {
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 8px;
            font-size: 14px;
            line-height: 1.42857143;
            color: #555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            &[disabled] {
                background-color: #eee;
                opacity: 1;
            }
        }
    }
}

// Cancel Appointment Block
.calendar-holder__cancel {
    margin: 0;
}

.cancel-col {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.cancel-btn {
    margin: 20px 0 0;
    max-width: 165px;
}

@media (max-width: 1199px) {
    .cancel-col {
        align-items: flex-start;
    }

    .cancel-btn {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .calendar-holder__cancel {
        display: flex;
        flex-direction: column;
    }

    .control-lead {
        margin: 0 -15px;
    }
}

.cancel-block-show {
    margin-top: 30px;
    min-width: 300px;
    max-width: 400px;
    position: relative;
    .panel {
        padding: 15px;
        h4 {
            font-size: 20px;
            letter-spacing: 1px;
        }
    }

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.text-in-cancel {
    font-size: 18px;
    font-weight: 200;
    margin: 0 0 15px;
    letter-spacing: 1.5px;
}

.bottom-holder-btns {
    display: flex;
    text-align: center;
    justify-content: flex-end;
    .cancel-no {
        border: 0;
        color: #555;
        background: transparent;
        font-size: 20px;
    }

    .cancel-no:hover {
        background: none;
    }

    .btn {
        margin: 0;
    }
}

.message-block-holder {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    .page-header {
        border-bottom: none;
    }
    .btn {
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        margin: 0;
        height: 46px;
        margin: 10px 0 20px;
    }
}

.order-block {
    display: flex;
    flex-direction: column;
    .draggable-widgets {
        display: flex;
        justify-content: space-between;
        h2 {
            font-size: 28px;
        }
        .holder-order__col {
            width: 49%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            .draggable-widgets > div > div {
                display: block;
            }
            .draggable-widget-col,
            .draggable-widget-col-drag-over {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                div {
                    width: 100%;
                }
            }
        }
    }
}

// crm-config - Custom Fields
.group-field {
    @media screen and (max-width: 520px) {
        display: block;
        overflow-x: scroll;
    }
    > div {
        table {
            tbody {
                tr {
                    td {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    .user-field {
        min-width: 80px;
    }
}

//  Added hand cursor for the icon in Calendar Rules
.slot-popover {
    .glyphicon {
        cursor: pointer;
    }
}

.panel-block-passive {
    .panel-info {
        border-color: #ddd;
    }

    .panel-heading {
        color: #333;
        background: #f9f9f9;
        border-color: #ddd;
    }

    .table-bordered:not(.include-cells) {
        border-top: 1px solid #ddd;
    }
}

.live-chat-block {
    min-width: 595px;

    @media (max-width: 480px) {
        min-width: 300px;
    }
}

.select-companys {
    .block-companies-holder {
        float: inherit;
        padding: 0;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
            align-items: flex-start;
            flex-direction: column;
        }
        .control-label {
            margin-right: 10px;
            min-width: 115px;
        }
        .name-company {
            width: 100%;
            font-size: 16px;
            display: inline-block;
            width: calc(100% - 50%);
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }

    .block-company-chat {
        .panel-body {
            .form-group {
                p {
                    height: 32px;
                    font-size: 18px;
                    border-bottom: 1px solid color('secondary_color');
                }
            }
        }

        .save-changes {
            .row {
                button {
                    min-width: 88px;
                    margin-right: 15px;
                }
            }
        }

        .preview {
            p {
                font-size: 18px;
            }
        }
    }
}

.edit-new-agency {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
}

/* ======= Override for panel-body to prevent long content from breaking out ======== */
.panel-body {
    word-break: break-all;
}

/* ======= Appointment Types ======= */
.two-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
